import React, { useContext } from "react";
import { EEducationExpenseFields } from "../../../containers/EducationExpenses/EducationExpense/EducationExpense";
import EducationExpenseContext from "../../../containers/EducationExpenses/EducationExpense/EducationExpenseContext";
import EducationExpenseSection from "../EducationExpenseSection/EducationExpenseSection";

const EducationExpenseTravel: React.FC = () => {
  const { createInput } = useContext(
    EducationExpenseContext
  );
  return (
    <EducationExpenseSection title="Laske ALV">
      <>
        {createInput(EEducationExpenseFields.calculateVAT)}
      </>
    </EducationExpenseSection>
  );
};

export default EducationExpenseTravel;
