import axios, { AxiosError } from "axios";

const hostname = window && window.location && window.location.hostname;

let apiPath = "/";
let withCredentials = true;
if (hostname === "localhost") {
  apiPath = "http://localhost:8501";
} else {
  apiPath = "https://passi.suek.fi";
}
apiPath += "/rest";

const instance = axios.create({
  baseURL: apiPath,
  withCredentials: withCredentials,
});

export const getErrorMessage = (error: AxiosError) => {
  let errorMessage = error.message;
  if (error.response) {
    errorMessage = error.response.data.Message;
  }
  return errorMessage;
};

export default instance;
