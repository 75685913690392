import { AxiosInstance } from 'axios';
import React, { useLayoutEffect, useState } from 'react';

import CustomDialog from '../../components/UI/CustomDialog/CustomDialog';

const withErrorHandler = function<T>(
  WrappedComponent: React.FC<T>,
  axios: AxiosInstance
) {
  return (props: T) => {
    const [error, setError] = useState(false);

    useLayoutEffect(() => {
      const reqInterceptor = axios.interceptors.request.use(req => {
        setError(false);
        return req;
      });
      const resInterceptor = axios.interceptors.response.use(
        res => res,
        error => {
          let errorMessage = error.response ? error.response.data : error.message;
          if(typeof errorMessage === 'object') {
            errorMessage = errorMessage.Message;
          }
          if(errorMessage === 'NO_AUTH') errorMessage = 'Ei käyttöoikeutta.';
          setError(errorMessage);
        }
      );

      return () => {
        axios.interceptors.request.eject(reqInterceptor);
        axios.interceptors.response.eject(resInterceptor);
      };
    }, []);

    return (
      <React.Fragment>
        <CustomDialog
          open={!!error}
          title="Virhe"
          content={error}
          onOk={() => setError(false)}
          buttonText="Sulje"
        />
        <WrappedComponent {...props} />
      </React.Fragment>
    );
  };
};

export default withErrorHandler;
