import { ISearchAction } from "../../interfaces/action/ISearchAction";
import { ISearchState } from "../../interfaces/state/ISearchState";
import { updateObject } from "../../shared/utility";
import { ActionTypes } from "../actions/actionTypes";

type ReducerState = ISearchState;
type ReducerSignature = (
  state: ReducerState,
  action: ISearchAction
) => ReducerState;

const initialState: ReducerState = {
  users: [],
  loading: false,
  error: null,
};

const getSearchUsersStart: ReducerSignature = (state, action) => {
  return updateObject(state, { users: [], loading: true });
};

const getSearchUsersSuccess: ReducerSignature = (state, action) => {
  return updateObject(state, {
    loading: false,
    users: action.users,
  });
};

const getSearchUsersFail: ReducerSignature = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const reducer = (state = initialState, action: ISearchAction): ReducerState => {
  switch (action.type) {
    case ActionTypes.SEARCH_GET_SEARCH_USERS_START:
      return getSearchUsersStart(state, action);
    case ActionTypes.SEARCH_GET_SEARCH_USERS_SUCCESS:
      return getSearchUsersSuccess(state, action);
    case ActionTypes.SEARCH_GET_SEARCH_USERS_FAIL:
      return getSearchUsersFail(state, action);
    default:
      return state;
  }
};

export default reducer;
