import {
  Checkbox,
  Chip,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import React from "react";

import {
  IEducationExpense,
  EEducationExpenseStatus,
  EEducationExpenseType,
} from "../../../interfaces/domain";
import EducationExpenseStatus from "../../EducationExpense/EducationExpenseStatus/EducationExpenseStatus";

interface IProps {
  educationExpenses: IEducationExpense[] | null;
  isAdmin: boolean;
  onToggle: (id: string) => void;
  onToggleAll: () => void;
  selectedItems: string[];
  onEdit: (id: string) => void;
  onDelete: (education: IEducationExpense) => void;
  total: number | null;
}

const EducationExpensesList: React.FC<IProps> = ({
  educationExpenses,
  isAdmin,
  selectedItems,
  onToggle,
  onToggleAll,
  onEdit,
  onDelete,
  total,
}) => {
  if (!educationExpenses || !educationExpenses.length) {
    return (
      <Typography style={{ fontWeight: "bold", padding: "1rem" }} gutterBottom>
        Ei luentopalkkioita.
      </Typography>
    );
  }

  const renderTableHead = () => {
    const headers = [
      "Tila",
      "Luonti pvm",
      "Nimi",
      "Koulutukset",
      "Matka",
      "Matkan kesto",
      "Yhteensä",
      "",
    ];
    return (
      <TableHead>
        <TableRow>
          {isAdmin && (
            <>
              <TableCell>
                <Checkbox
                  onClick={onToggleAll}
                  checked={selectedItems.length === educationExpenses.length}
                />
              </TableCell>
              <TableCell>
                Tyyppi
              </TableCell>
            </>
          )}
          {headers.map((headerText) => (
            <TableCell key={headerText}>{headerText}</TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  };

  return (
    <div style={{ overflowX: "auto", padding: "16px" }}>
      <Table>
        {renderTableHead()}
        <TableBody>
          {educationExpenses &&
            educationExpenses.map((education) => {
              const {
                id,
                status,
                createdOn,
                travelStartDateString,
                travelEndDateString,
                travelHours,
                travelMinutes,
                userName,
                educations,
                calculateVAT,
                total,
                total_vat,
                type,
              } = education;

              const canDelete =
                isAdmin || status === EEducationExpenseStatus.NEW;
              return (
                <TableRow key={education.id}>
                  {isAdmin && (
                    <>
                    <TableCell>
                      <Checkbox
                        onClick={() => onToggle(id)}
                        checked={selectedItems.includes(id)}
                        disabled={
                          ![
                            EEducationExpenseStatus.CONFIRMED,
                            EEducationExpenseStatus.SEND_TO_NV_FAILED,
                          ].includes(education.status)
                        }
                      />
                    </TableCell>
                    <TableCell>
                      {type === EEducationExpenseType.EDUCATION && <Chip color={"primary"} label={"Koulutus"} />}
                      {type === EEducationExpenseType.AUDITING && <Chip color={"secondary"} label={"Auditointi"} />}
                    </TableCell>
                    </>
                  )}

                  <TableCell>
                    <EducationExpenseStatus status={status} isAdmin={isAdmin} />
                  </TableCell>
                  <TableCell>{createdOn}</TableCell>
                  <TableCell>{userName}</TableCell>
                  <TableCell>
                    {educations &&
                      educations.map((education) => education.name).join(", ")}
                  </TableCell>
                  <TableCell>
                    {travelStartDateString} - {travelEndDateString}
                  </TableCell>
                  <TableCell>
                    {travelHours ? `${travelHours} h` : ""}{" "}
                    {travelMinutes ? `${travelMinutes} min` : ""}
                  </TableCell>
                  <TableCell>
                    {calculateVAT && calculateVAT.indexOf("Y") !== -1
                      ? `${total_vat} €`
                      : `${total} €`}
                  </TableCell>
                  <TableCell style={{ whiteSpace: "nowrap" }}>
                    <IconButton
                      aria-label="Muokkaa luentopalkkiota"
                      onClick={() => onEdit(id)}
                    >
                      {canDelete ? <EditIcon /> : <VisibilityIcon /> }
                    </IconButton>
                    {canDelete && (
                      <IconButton
                        aria-label="Poista luentopalkkio"
                        onClick={() => onDelete(education)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          <TableRow>
            <TableCell colSpan={isAdmin ? 7 : 6}></TableCell>
            <TableCell style={{ whiteSpace: "nowrap" }}>{total} €</TableCell>
            <TableCell style={{ whiteSpace: "nowrap" }}>
              {educationExpenses.length} kpl
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
};

export default EducationExpensesList;
