import { IUnionEducationAction } from './../../interfaces/action/IUnionEducationAction';
import { Dispatch } from 'redux';

import axios from '../../axiosCF';
import { IUnion } from '../../interfaces/domain/IUnion';
import { IUnionEducation } from '../../interfaces/domain/IUnionEducation';
import { ActionTypes } from './actionTypes';


export const saveUnionEducationSuccess = (unionEducation: IUnionEducation): IUnionEducationAction => {
    return {
        type: ActionTypes.SAVE_UNION_EDUCATION_SUCCESS,
        unionEducation
    }
};

export const saveUnionEducationFail = (error: string): IUnionEducationAction => {
    return {
        type: ActionTypes.SAVE_UNION_EDUCATION_FAIL,
        error
    }
};

export const saveUnionEducationStart = (): IUnionEducationAction => {
    return {
        type: ActionTypes.SAVE_UNION_EDUCATION_START
    }
}

export const saveUnionEducation = (formData: FormData) => {
    return async (dispatch: Dispatch): Promise<IUnionEducation | null> => {
        dispatch(saveUnionEducationStart());
        try {
            const response = await axios.post(`/api/unioneducations/add`, formData, {
                headers: {
                  "Content-Type": "multipart/form-data"
                }
            });
            const unionEducation = response.data as IUnionEducation;
            dispatch(saveUnionEducationSuccess(unionEducation));
            return unionEducation;
        } catch (error) {
            dispatch(saveUnionEducationFail(error as string));
            return null;
        }        
    };
};

export const getUnionsSuccess = (unions: IUnion[]): IUnionEducationAction => {
    return {
        type: ActionTypes.GET_UNIONS_SUCCESS,
        unions
    }
};

export const getUnionsFail = (error: string): IUnionEducationAction => {
    return {
        type: ActionTypes.GET_UNIONS_FAIL,
        error
    }
};

export const getUnionsStart = (): IUnionEducationAction => {
    return {
        type: ActionTypes.GET_UNIONS_START
    }
}

export const getUnions = () => {
    return async (dispatch: Dispatch): Promise<IUnion[] | null> => {
        dispatch(getUnionsStart());
        try {
            const response = await axios.get<IUnion[]>(`/api/unioneducations/unions`);
            const unions = response.data;
            dispatch(getUnionsSuccess(unions));
            return unions;
        } catch (error) {
            dispatch(getUnionsFail(error as string));
            return null;
        }        
    };
};