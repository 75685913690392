import React from "react";

import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CardActions from "@material-ui/core/CardActions";
import IconButton from "@material-ui/core/IconButton";

import GetAppIcon from "@material-ui/icons/GetApp";
import DeleteIcon from "@material-ui/icons/Delete";
import { LinearProgress } from "@material-ui/core";

const classes = require("./FileItem.module.scss");

interface IProps {
  name: string;
  progress?: number;
  isUploading: boolean;
  onDownload?: () => void;
  onDelete: () => void;
}

const FileItem: React.FC<IProps> = ({ name, progress, isUploading, onDownload, onDelete }) => (
  <Grid item xs={12} sm={6} md={4} lg={3}>
    <Card className={classes.Card}>
      <CardContent>
        <Typography variant="body1" component="p">
          {name}
        </Typography>
        {progress && (
          <div style={{ marginTop: '.5rem' }}>
            <LinearProgress variant="determinate" value={progress} />
          </div>
        )}
      </CardContent>
      <CardActions className={classes.CardActions}>
        {!isUploading && onDownload && (
          <IconButton aria-label="Lataa tiedosto" onClick={onDownload}>
            <GetAppIcon />
          </IconButton>
        )}
        {!isUploading && (
          <IconButton aria-label="Poista tiedosto" onClick={onDelete}>
            <DeleteIcon />
          </IconButton>
        )}
        
      </CardActions>
    </Card>
  </Grid>
);

export default FileItem;
