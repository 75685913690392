import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

import { Link } from "react-router-dom";

import MenuIcon from "@material-ui/icons/Menu";
import React from "react";

import Logo from "../Logo/Logo";
import { loginUiUrl } from "../../config";

const classes = require("./Navigation.module.scss");

interface IProps {
  loading: boolean;
  isAuthenticated: boolean;
  onToggleDrawer: () => void;
}

const Navigation: React.FC<IProps> = ({
  loading,
  isAuthenticated,
  onToggleDrawer
}) => {
  let authButton: JSX.Element | null = (
    <Button color="inherit" onClick={() => window.location.href = loginUiUrl}>
      Kirjaudu sisään
    </Button>
  );


  let accountManagemenetButton: JSX.Element | null = null;
  if (isAuthenticated) {
    authButton = (
      <Button color="inherit" component={Link} to="/logout">
        Kirjaudu ulos
      </Button>
    );
    accountManagemenetButton = (
      <Button color="inherit" href={loginUiUrl}>
        Tilin hallinta
      </Button>
    );
  }

  let menuButton: JSX.Element | null = (
    <IconButton
      onClick={onToggleDrawer}
      className={classes.root}
      color="inherit"
      aria-label="Menu"
    >
      <MenuIcon />
    </IconButton>
  );

  if (loading) {
    accountManagemenetButton = null;
    authButton = null;
    menuButton = null;
  }

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Toolbar>
          {menuButton}
          <div className={classes.LogoContainer}>
            <Logo height={50} />
          </div>
          <Typography variant="body1" color="inherit" className={classes.flex}>
            Koulutus
          </Typography>
          {accountManagemenetButton}
          {authButton}
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Navigation;
