import axios, { AxiosRequestConfig } from 'axios'

const hostname = window && window.location && window.location.hostname;

let apiPath = '/';
let withCredentials = false;
if(hostname === 'localhost') {
    apiPath = 'http://localhost:8080/';
    withCredentials = true;
}

const instance = axios.create({
    baseURL: apiPath,
    withCredentials: withCredentials,
    headers: {
        'Content-Type': 'application/json'
    }
});

instance.interceptors.request.use((config:AxiosRequestConfig) => {
    const token = localStorage.getItem('token');
    config.headers['X-Auth'] = token ? token : '';
    return config;
});

export default instance;