import {
  Button,
  CircularProgress,
  LinearProgress,
  Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import EducationExpenseBasicInformation from "../../../components/EducationExpense/EducationExpenseBasicInformation/EducationExpenseBasicInformation";
import EducationExpenseCalculations from "../../../components/EducationExpense/EducationExpenseCalculations/EducationExpenseCalculations";
import EducationExpenseCostCenter from "../../../components/EducationExpense/EducationExpenseCostCenter/EducationExpenseCostCenter";
import EducationExpenseEducations from "../../../components/EducationExpense/EducationExpenseEducations/EducationExpenseEducations";
import EducationExpenseExtraFees from "../../../components/EducationExpense/EducationExpenseExtraFees/EducationExpenseExtraFees";
import EducationExpenseDate from "../../../components/EducationExpense/EducationExpenseDate/EducationExpenseDate";
import EducationExpenseTravel from "../../../components/EducationExpense/EducationExpenseTravel/EducationExpenseTravel";
import EducationExpenseVAT from "../../../components/EducationExpense/EducationExpenseVAT/EducationExpenseVAT";
import CustomDialog from "../../../components/UI/CustomDialog/CustomDialog";
import { EInputType } from "../../../components/UI/Input/Input";
import {
  costCenterOptions,
  educationExpenseTypeOptions,
  EEducationExpenseType,
  ETravelType,
  travelTypeOptions,
} from "../../../data/education-expense";
import { useBlurBeforeUnload } from "../../../hooks/useBlurBeforeUnload";
import { useCreateInput } from "../../../hooks/useCreateInput";
import { EEducationExpenseStatus } from "../../../interfaces/domain/IEducationExpense";
import { IAppState, IAuthState } from "../../../interfaces/state";
import { IEducationExpenseState } from "../../../interfaces/state/IEducationExpenseState";
import {
  disableInputs,
  IControls,
  initForm,
  IOption,
  TFormElementValue,
} from "../../../shared/utility";
import * as actions from "../../../store/actions";
import { TThunkDispatch } from "../../../store/TThunkDispatch";
import classes from "./EducationExpense.module.scss";
import EducationExpenseContext from "./EducationExpenseContext";

interface IEducationExpenseParams {
  id: string;
}

export enum EEducationExpenseFields {
  type = "type",
  educations = "educations",
  travelStartDate = "travelStartDate",
  travelEndDate = "travelEndDate",
  distance = "distance",
  travelKm = "travelKm",
  travelRoute = "travelRoute",
  infoSession = "infoSession",
  remoteSession = "remoteSession",
  invoicedBy = "invoicedBy",
  invoicedDate = "invoicedDate",
  reviewedBy = "reviewedBy",
  reviewedDate = "reviewedDate",
  confirmedBy = "confirmedBy",
  confirmedDate = "confirmedDate",
  travelType = "travelType",
  travelTypeOther = "travelTypeOther",
  passengers = "passengers",
  extraFees = "extraFees",
  calculateVAT = "calculateVAT",
  costCenter = "costCenter",
}

const initControls: IControls = {
  controls: {
    [EEducationExpenseFields.type]: {
      type: EInputType.select,
      value: "",
      label: "Tyyppi",
      options: educationExpenseTypeOptions,
      validation: {
        required: true,
      },
      valid: false,
    },
    [EEducationExpenseFields.travelStartDate]: {
      type: EInputType.datetime,
      value: null,
      label: "Matka alkoi",
      valid: false,
    },
    [EEducationExpenseFields.travelEndDate]: {
      type: EInputType.datetime,
      value: null,
      label: "Matka päättyi",
      valid: false,
    },
    [EEducationExpenseFields.infoSession]: {
      type: EInputType.checkbox,
      value: [],
      options: [{ value: "Y", text: "Lyhyt infotilaisuus" }],
      label: "Lyhyt infotilaisuus",
      valid: false,
    },
    [EEducationExpenseFields.remoteSession]: {
      type: EInputType.checkbox,
      value: [],
      options: [{ value: "Y", text: "Etäkoulutus" }],
      label: "Etäkoulutus",
      valid: false,
    },
    [EEducationExpenseFields.distance]: {
      type: EInputType.number,
      value: "",
      label: "Etäisyys kodin ja koulutuspaikan välillä",
      validation: {
        required: true,
      },
      valid: false,
    },
    [EEducationExpenseFields.travelKm]: {
      type: EInputType.number,
      value: "",
      label: "Matkan kokonaispituus km",
      validation: {
        required: true,
      },
      valid: false,
    },
    [EEducationExpenseFields.travelRoute]: {
      type: EInputType.text,
      value: "",
      label: "Matkareitti (ilmoita aina)",
      validation: {
        required: true,
      },
      valid: false,
    },
    [EEducationExpenseFields.invoicedBy]: {
      type: EInputType.text,
      value: "",
      label: "",
      validation: {
        required: true,
      },
      valid: false,
    },
    [EEducationExpenseFields.invoicedDate]: {
      type: EInputType.date,
      value: null,
      label: "",
      valid: false,
    },
    [EEducationExpenseFields.reviewedBy]: {
      type: EInputType.text,
      value: "",
      label: "",
      validation: {
        required: true,
      },
      valid: false,
    },
    [EEducationExpenseFields.reviewedDate]: {
      type: EInputType.date,
      value: null,
      label: "",
      valid: false,
    },
    [EEducationExpenseFields.confirmedBy]: {
      type: EInputType.text,
      value: "",
      label: "",
      validation: {
        required: true,
      },
      valid: false,
    },
    [EEducationExpenseFields.confirmedDate]: {
      type: EInputType.date,
      value: null,
      label: "",
      valid: false,
    },
    [EEducationExpenseFields.travelType]: {
      type: EInputType.select,
      value: "",
      label: "Matkustustapa",
      options: travelTypeOptions,
      validation: {
        required: true,
      },
      valid: false,
    },
    [EEducationExpenseFields.travelTypeOther]: {
      type: EInputType.text,
      value: "",
      label: "Muu, mikä?",
      validation: {
        required: true,
      },
      valid: false,
    },
    [EEducationExpenseFields.passengers]: {
      type: EInputType.text,
      value: "",
      label: "Matkustaja(t)",
      validation: {
        required: true,
      },
      valid: false,
    },
    [EEducationExpenseFields.calculateVAT]: {
      type: EInputType.checkbox,
      value: "",
      options: [{ value: "Y", text: "ALV –maksuvelvollinen → + ALV 24%" }],
      label: "ALV –maksuvelvollinen → + ALV 24%",
      valid: false,
    },
    [EEducationExpenseFields.costCenter]: {
      type: EInputType.select,
      value: "",
      label: "Kustannuspaikka",
      options: costCenterOptions,
      validation: {
        required: true,
      },
      valid: false,
    },
  },
  isValid: false,
};

const EducationExpense: React.FC = () => {
  const history = useHistory();
  useBlurBeforeUnload();
  const dispatch = useDispatch();
  const { id } = useParams<IEducationExpenseParams>();
  const [controls, setControls] = useState(initControls);
  const [educationOptions, setEducationOptions] = useState<IOption[]>([]);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isEdit, setEdit] = useState(false);

  const { user } = useSelector<IAppState, IAuthState>((state) => state.auth);
  const isAdmin = (user && user.permissions.koulutusAdmin) || false;

  useEffect(() => {
    dispatch(actions.getEducationExpense(id));
  }, [dispatch, id]);

  const startDate = controls.controls[EEducationExpenseFields.travelStartDate]
    .value as string;
  const endDate = controls.controls[EEducationExpenseFields.travelEndDate]
    .value as string;

  const {
    loading,
    educationExpense,
    error,
    sendError,
    sendLoading,
    addableEducations,
  } = useSelector<IAppState, IEducationExpenseState>(
    (state) => state.educationExpenses
  );

  const type = educationExpense && educationExpense.type;

  useEffect(() => {
    if (startDate && endDate) {
      if (moment(startDate).isValid() && moment(endDate).isValid()) {
        dispatch(actions.getAddableEducations(id, startDate, endDate));
      }
    }
  }, [dispatch, id, startDate, endDate, type]);

  useEffect(() => {
    if (educationExpense) {
      const isDisabled = educationExpense.status !== EEducationExpenseStatus.NEW && !isEdit;
      setControls(initForm(initControls.controls, educationExpense, isDisabled));
      setIsDisabled(isDisabled);
    }
  }, [educationExpense, isEdit]);

  useEffect(() => {
    setIsDisabled(!isEdit);
    disableInputs(!isEdit, setControls);
  }, [isEdit]);

  useEffect(() => {
    if (addableEducations) {
      setEducationOptions(
        addableEducations.map((education) => ({
          value: education.id.toString(),
          text: education.name,
        }))
      );
    } else {
      setEducationOptions([]);
    }
  }, [addableEducations]);

  const onUpdate = useCallback(
    async (field: EEducationExpenseFields, value: TFormElementValue) => {
      await (dispatch as TThunkDispatch)(
        actions.updateEducationExpense({ id, [field]: value ? value : "" })
      );
      return Promise.resolve(true);
    },
    [dispatch, id]
  );

  const educationsChangeHandler = useCallback(
    async (educations: number[]) => {
      await onUpdate(EEducationExpenseFields.educations, educations);
      dispatch(actions.getAddableEducations(id, startDate, endDate));
    },
    [onUpdate, dispatch, id, startDate, endDate,]
  );

  const createInput = useCreateInput(controls, setControls, {
    onBlur: (value, inputName) =>
      onUpdate(inputName as EEducationExpenseFields, value),
  });

  const submitHandler = () => {
    dispatch(actions.sendEducationExpense(id));
    setConfirmOpen(false);
  };

  if (loading) return <LinearProgress color="primary" />;

  if (error) return <Alert severity="error">{error}</Alert>;

  if (!educationExpense) {
    return (
      <Typography gutterBottom component="b">
        Luentopalkkiota ei löytynyt.
      </Typography>
    );
  }

  const travelType =
    (controls.controls[EEducationExpenseFields.travelType]
      .value as ETravelType) || ETravelType.OWN_CAR;

  const isNew = educationExpense.status === EEducationExpenseStatus.NEW;
  const isConfirmed = [
    EEducationExpenseStatus.CONFIRMED,
    EEducationExpenseStatus.SEND_TO_NV_FAILED,
    EEducationExpenseStatus.SENT_TO_NV,
  ].includes(educationExpense.status);


  const canEnableEdit = isAdmin && [EEducationExpenseStatus.CONFIRMED, EEducationExpenseStatus.SENT].includes(educationExpense.status);

  const isRemoteSession = (controls.controls[EEducationExpenseFields.remoteSession].value as string[]).includes("Y");

  const { invoicedBy, invoicedDate, confirmedBy, confirmedDate } =
    educationExpense;

  return (
    <EducationExpenseContext.Provider
      value={{
        educationExpense,
        createInput,
        educationOptions,
        travelType,
        isDisabled,
        isRemoteSession,
        isAdmin
      }}
    >
      <div className={classes.Container}>
        <CustomDialog
          title={isNew ? "Lähetä luentopalkkio" : "Vahvista luentopalkkio"}
          content={
            isNew
              ? "Haluatko varmasti lähettää luentopalkkion?"
              : "Haluatko varmasti vahvistaa luentopalkkion?"
          }
          buttonText={isNew ? "Lähetä" : "Vahvista"}
          onCancel={() => setConfirmOpen(false)}
          onOk={submitHandler}
          open={!!confirmOpen}
        />
        <Typography variant="h4" gutterBottom>
          Luentopalkkio
        </Typography>
        <EducationExpenseBasicInformation />
        <EducationExpenseTravel />
        <EducationExpenseEducations
          onEducationsChange={educationsChangeHandler}
        />
        <EducationExpenseExtraFees />
        <EducationExpenseVAT />
        <EducationExpenseCalculations />
        {educationExpense.status !== EEducationExpenseStatus.NEW && (
          <>
            <EducationExpenseDate
              title="Laskun esittäjä"
              username={invoicedBy}
              date={invoicedDate}
            />
            {isAdmin && type === EEducationExpenseType.EDUCATION && <EducationExpenseCostCenter disabled={isConfirmed} />}
            {isAdmin && isConfirmed && (
              <EducationExpenseDate
                title="Tarkistanut"
                username={confirmedBy}
                date={confirmedDate}
              />
            )}
          </>
        )}
        {sendError && (
          <Alert severity="error" className={classes.SendError}>
            {sendError}
          </Alert>
        )}
        <div className={classes.ButtonContainer}>
          {sendLoading && (
            <CircularProgress
              size="1.5rem"
              color="primary"
              style={{ marginRight: ".5rem" }}
            />
          )}
          {(!isDisabled || (!isConfirmed && isAdmin)) && !sendLoading && !isEdit && (
            <Button
              style={{ marginRight: ".5rem" }}
              onClick={() => setConfirmOpen(true)}
              variant="contained"
              color="primary"
            >
              {isNew ? "Lähetä" : "Vahvista"}
            </Button>
          )}
          {canEnableEdit && (
            <Button
              style={{ marginRight: ".5rem" }}
              onClick={() => setEdit((edit) => !edit)}
              variant="contained"
              color={isEdit ? 'primary' : 'default'}
            >
              {isEdit ? "Valmis" : "Muokkaa"}
            </Button>
          )}
          <Button onClick={() => history.goBack()} variant="contained">
            Takaisin
          </Button>
        </div>
      </div>
    </EducationExpenseContext.Provider>
  );
};

export default EducationExpense;
