import { updateObject } from "../../shared/utility";
import { ActionTypes } from "../actions/actionTypes";
import { IAuthAction } from "../../interfaces/action/IAuthAction";
import { IAuthState } from "../../interfaces/state/IAuthState";

type ReducerSignature = (state: IAuthState, action: IAuthAction) => IAuthState;

const initialState: IAuthState = {
  token: null,
  user: null,
  loading: false,
  error: null,
  logout: false,
};

const authStart: ReducerSignature = (state, action) => {
  return updateObject(state, { error: null, loading: true });
};

const authSuccess: ReducerSignature = (state, action) => {
  return updateObject(state, {
    token: action.token,
    user: action.user,
    error: null,
    loading: false,
    logout: false,
  });
};

const authFail: ReducerSignature = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};

const logoutStart: ReducerSignature = (state, action) => {
  return updateObject(state, { error: null, loading: true });
};

const logoutSuccess: ReducerSignature = (state, action) => {
  return updateObject(state, {
    user: null,
    error: null,
    loading: false,
    token: null,
    logout: true
  });
};

const logoutFail: ReducerSignature = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};

const getCurrentUserStart: ReducerSignature = (state, action) => {
  return updateObject(state, { error: null, loading: true });
};

const getCurrentUserSuccess: ReducerSignature = (state, action) => {
  return updateObject(state, {
    user: action.user,
    error: null,
    loading: false
  });
};

const getCurrentUserFail: ReducerSignature = (state, action) => {
  return updateObject(state, {
    loading: false,
    user: null
  });
};

const clearError: ReducerSignature = (state, action) => {
  return updateObject(state, {
    error: null
  });
};

const reducer = (state = initialState, action: IAuthAction): IAuthState => {
  switch (action.type) {
    case ActionTypes.AUTH_START:
      return authStart(state, action);
    case ActionTypes.AUTH_SUCCESS:
      return authSuccess(state, action);
    case ActionTypes.AUTH_FAIL:
      return authFail(state, action);
    case ActionTypes.LOGOUT_START:
      return logoutStart(state, action);
    case ActionTypes.LOGOUT_SUCCESS:
      return logoutSuccess(state, action);
    case ActionTypes.LOGOUT_FAIL:
      return logoutFail(state, action);
    case ActionTypes.GET_CURRENT_USER_START:
      return getCurrentUserStart(state, action);
    case ActionTypes.GET_CURRENT_USER_SUCCESS:
      return getCurrentUserSuccess(state, action);
    case ActionTypes.GET_CURRENT_USER_FAIL:
      return getCurrentUserFail(state, action);
    case ActionTypes.CLEAR_ERROR:
      return clearError(state, action);
    default:
      return state;
  }
};

export default reducer;
