import React from 'react';

import { IAttachment } from '../../interfaces/domain';

interface IProps {
  attachments: IAttachment[] | null;
}

const Attachments: React.FC<IProps> = ({ attachments }) => {
  const getContent = () => {
    if (!attachments || !attachments.length) {
      return <p>Ei liitteitä.</p>;
    }

    return attachments.map(attachment => (
      <div key={attachment.id}>
        <a href={attachment.link} target="_blank" rel="noopener noreferrer">
          {attachment.name}
        </a>
      </div>
    ));
  };

  return <div>{getContent()}</div>;
};

export default Attachments;
