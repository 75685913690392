import { Dispatch } from 'redux';

import axios from '../../axiosCF';
import { ISportMedicineEducationAction } from '../../interfaces/action/ISportMedicineEducationAction';
import { ISportMedicineEducation } from '../../interfaces/domain/ISportMedicineEducation';
import { ActionTypes } from './actionTypes';


type Action = ISportMedicineEducationAction;

const saveSportMedicineEducationStart = (): Action => {
    return {
        type: ActionTypes.SAVE_SPORT_MEDICINE_EDUCATION_START,
    }
}

const saveSportMedicineEducationSuccess = (sportMedicineEducation: ISportMedicineEducation): Action => {
    return {
        type: ActionTypes.SAVE_SPORT_MEDICINE_EDUCATION_SUCCESS,
        sportMedicineEducation
    }
};

const saveSportMedicineEducationFail = (error: string): Action => {
    return {
        type: ActionTypes.SAVE_SPORT_MEDICINE_EDUCATION_FAIL,
        error
    }
};

export const saveSportMedicineEducation = (formData: FormData) => {
    return async (dispatch: Dispatch): Promise<ISportMedicineEducation | null> => {
        dispatch(saveSportMedicineEducationStart());
        try {
            const response = await axios.post(`/api/sportmedicineeducation/add`, formData, {
                headers: {
                  "Content-Type": "multipart/form-data"
                }
            });
            const sportMedicineEducation = response.data as ISportMedicineEducation;
            dispatch(saveSportMedicineEducationSuccess(sportMedicineEducation));
            return sportMedicineEducation;
        } catch (error) {
            dispatch(saveSportMedicineEducationFail(error as string));
            return null;
        }        
    };
};