export enum ETravelType {
  OWN_CAR = "OWN_CAR",
  MOTORBIKE = "MOTORBIKE",
  BICYCLE = "BICYCLE",
  OTHER = "OTHER",
}

export const travelTypeOptions = [
  { value: ETravelType.OWN_CAR, text: "Oma auto" },
  { value: ETravelType.MOTORBIKE, text: "Moottoripyörä" },
  { value: ETravelType.BICYCLE, text: "Polkupyörä" },
  { value: ETravelType.OTHER, text: "Muu" },
];

export const costCenterOptions = [
  { value: "31", text: "Koulutus" },
  { value: "32", text: "Kouluttajien koulutus" },
];

export enum EEducationExpenseType {
  EDUCATION = 'EDUCATION',
  AUDITING = 'AUDITING',
}

export const educationExpenseTypeOptions = [
  { value: EEducationExpenseType.EDUCATION, text: "Koulutus" },
  { value: EEducationExpenseType.AUDITING, text: "Auditointi" },
];
