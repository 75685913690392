import React from "react";

import suekLogo from "../../assets/images/suek.svg";
const classes = require("./Logo.module.scss");

interface IProps {
  height: number;
}

const Logo: React.FC<IProps> = ({ height }) => (
  <div className={classes.Logo}>
    <img src={suekLogo} alt="SUEK" height={height} />
  </div>
);

export default Logo;
