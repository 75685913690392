import { Dispatch } from "redux";

import axios from "../../axios";
import { IMaterial } from "../../interfaces/domain";
import { IMaterialsAction } from "../../interfaces/action/IMaterialsAction";
import { IAppState } from "../../interfaces/state/IAppState";
import { ActionTypes } from "./actionTypes";

export const saveMaterialSuccess = (material: IMaterial): IMaterialsAction => {
  return {
    type: ActionTypes.SAVE_MATERIAL_SUCCESS,
    material
  };
};

export const saveMaterialFail = (error: string): IMaterialsAction => {
  return {
    type: ActionTypes.SAVE_MATERIAL_FAIL,
    error
  };
};

export const saveMaterialStart = (): IMaterialsAction => {
  return {
    type: ActionTypes.SAVE_MATERIAL_START
  };
};

export const saveMaterial = (data: Object): Function => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(saveMaterialStart());
    try {
      const response = await axios.post(`/json/materials`, JSON.stringify(data));
      const material = response.data as IMaterial;
      dispatch(saveMaterialSuccess(material));
    } catch (error) {
      console.log(error);
      dispatch(saveMaterialFail(error as string));
    }
  };
};

export const updateMaterialSuccess = (
  material: IMaterial
): IMaterialsAction => {
  return {
    type: ActionTypes.UPDATE_MATERIAL_SUCCESS,
    material
  };
};

export const updateMaterialFail = (error: string): IMaterialsAction => {
  return {
    type: ActionTypes.UPDATE_MATERIAL_FAIL,
    error
  };
};

export const updateMaterialStart = () => {
  return {
    type: ActionTypes.UPDATE_MATERIAL_START
  };
};

export const updateMaterial = (id: string, data: Object): Function => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(updateMaterialStart());
    try {
      const response = await axios.put(`/json/materials/${id}`, JSON.stringify(data));
      const material = response.data as IMaterial;
      dispatch(updateMaterialSuccess(material));
    } catch (error) {
      dispatch(updateMaterialFail(error as string));
    }
  };
};

export const fetchMaterialsSuccess = (
  materials: IMaterial[]
): IMaterialsAction => {
  return {
    type: ActionTypes.FETCH_MATERIALS_SUCCESS,
    materials
  };
};

export const fetchMaterialsFail = (error: string): IMaterialsAction => {
  return {
    type: ActionTypes.FETCH_MATERIALS_FAIL,
    error
  };
};

export const fetchMaterialsStart = (): IMaterialsAction => {
  return {
    type: ActionTypes.FETCH_MATERIALS_START
  };
};

export const fetchMaterials = (): Function => {
  return async (
    dispatch: Dispatch
  ): Promise<void> => {
    dispatch(fetchMaterialsStart());
    try {
      const res = await axios.get(`/json/materials`);
      const fetchedMaterials = res.data as IMaterial[];
      dispatch(fetchMaterialsSuccess(fetchedMaterials));
    } catch (err) {
      dispatch(fetchMaterialsFail(err as string));
    }
  };
};

export const setMaterial = (material: IMaterial): IMaterialsAction => {
  return {
    type: ActionTypes.SET_MATERIAL,
    material
  };
};

export const getMaterialSuccess = (material: IMaterial): IMaterialsAction => {
  return {
    type: ActionTypes.GET_MATERIAL_SUCCESS,
    material
  };
};

export const getMaterialFail = (error: string): IMaterialsAction => {
  return {
    type: ActionTypes.GET_MATERIAL_FAIL,
    error
  };
};

export const getMaterialStart = (): IMaterialsAction => {
  return {
    type: ActionTypes.GET_MATERIAL_START
  };
};

export const getMaterial = (id: string): Function => {
  return async (
    dispatch: Dispatch,
    getState: () => IAppState
  ): Promise<void> => {
    const material = getState().materials.material;

    if (material && material.id === id) {
      dispatch(getMaterialSuccess(material));
    } else {
      dispatch(getMaterialStart());
      try {
        const res = await axios.get(`/json/materials/${id}`);
        const material = res.data as IMaterial;
        dispatch(getMaterialSuccess(material));
      } catch (err) {
        dispatch(getMaterialFail(err as string));
      }
    }
  };
};

export const deleteMaterialSuccess = (
  material: IMaterial
): IMaterialsAction => {
  return {
    type: ActionTypes.DELETE_MATERIAL_SUCCESS,
    material
  };
};

export const deleteMaterialFail = (error: string): IMaterialsAction => {
  return {
    type: ActionTypes.DELETE_MATERIAL_FAIL,
    error
  };
};

export const deleteMaterialStart = (): IMaterialsAction => {
  return {
    type: ActionTypes.DELETE_MATERIAL_START
  };
};

export const deleteMaterial = (material: IMaterial): Function => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(deleteMaterialStart());
    try {
      await axios.delete(`/json/materials/${material.id}`);
      dispatch(deleteMaterialSuccess(material));
    } catch (err) {
      dispatch(deleteMaterialFail(err as string));
    }
  };
};

export const deleteAttachmentSuccess = (id: string): IMaterialsAction => {
  return {
    type: ActionTypes.DELETE_ATTACHMENT_SUCCESS,
    id
  };
};

export const deleteAttachmentFail = (error: string): IMaterialsAction => {
  return {
    type: ActionTypes.DELETE_ATTACHMENT_FAIL,
    error
  };
};

export const deleteAttachmentStart = () => {
  return {
    type: ActionTypes.DELETE_ATTACHMENT_START
  };
};

export const deleteAttachment = (id: string): Function => {
  return async (
    dispatch: Dispatch,
    getState: () => IAppState
  ): Promise<void> => {
    dispatch(deleteAttachmentStart());
    try {
      const material = getState().materials.material;
      if (!material) throw new Error("Material is not set");
      await axios.delete(
        `/json/materials/attachment/${id}?material=${material.id}`
      );
      dispatch(deleteAttachmentSuccess(id));
    } catch (err) {
      dispatch(deleteAttachmentFail(err as string));
    }
  };
};
