import React, { useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import classes from './Dropzone.module.scss';

interface IProps {
  onDrop: (acceptedFiles: File[]) => void;
  className?: string;
  text?: string;
  accept?: string;
  canPaste?: boolean;
}

const Dropzone: React.FC<IProps> = ({ onDrop, className, text, accept, canPaste }) => {

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  useEffect(() => {
    if (canPaste) {
      const listener = (ev: ClipboardEvent) => {
        const items = ev.clipboardData ? ev.clipboardData.items : null;
        if (items) {
          const item = items[0];
          if (item.kind === 'file') {
            const file = item.getAsFile();
            if (file) {
              onDrop([file])
            }
          }
        }
      }
      document.addEventListener("paste", listener);
      return () => {
        document.removeEventListener("paste", listener);
      }
    }

  }, [canPaste, onDrop]);

  const classNames = [classes.Container];

  if (className) {
    classNames.push(className);
  }

  return (
    <div className={classNames.join(' ')} {...getRootProps()}>
      <input {...getInputProps({ accept })} />
      {isDragActive ? (
        <>
          <p>Pudota tiedosto ja lataus aloitetaan heti.</p>
        </>
      ) : (
        <>
          <p>{text || 'Vedä tiedosto alueelle tai paina tästä ladataksesi tiedostoja.'}</p>
        </>
      )}
    </div>
  );
};

export default Dropzone;
