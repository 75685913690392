import { Dispatch } from 'redux';

import axios from '../../axiosCF';
import { ISuekEducationAction } from '../../interfaces/action/ISuekEducationAction';
import { ISuekEducation } from '../../interfaces/domain/ISuekEducation';
import { IFormData } from './../../shared/utility';
import { ActionTypes } from './actionTypes';

export const findSuekEducationsSuccess = (
  suekEducations: ISuekEducation[]
): ISuekEducationAction => {
  return {
    type: ActionTypes.FIND_SUEK_EDUCATION_SUCCESS,
    suekEducations
  };
};

export const findSuekEducationsFail = (error: string): ISuekEducationAction => {
  return {
    type: ActionTypes.FIND_SUEK_EDUCATION_FAIL,
    error
  };
};

export const findSuekEducationsStart = (): ISuekEducationAction => {
  return {
    type: ActionTypes.FIND_SUEK_EDUCATION_START
  };
};

export const findSuekEducations = (): Function => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(findSuekEducationsStart());
    try {
      const response = await axios.get("/api/suekEducations/find");
      dispatch(findSuekEducationsSuccess(response.data));
    } catch (error) {
      dispatch(findSuekEducationsFail(error as string));
    }
  };
};

export const getSuekEducationSuccess = (
  suekEducation: ISuekEducation
): ISuekEducationAction => {
  return {
    type: ActionTypes.GET_SUEK_EDUCATION_SUCCESS,
    suekEducation
  };
};

export const getSuekEducationFail = (error: string): ISuekEducationAction => {
  return {
    type: ActionTypes.GET_SUEK_EDUCATION_FAIL,
    error
  };
};

export const getSuekEducationStart = (): ISuekEducationAction => {
  return {
    type: ActionTypes.GET_SUEK_EDUCATION_START
  };
};

export const getSuekEducation = (id: number): Function => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(getSuekEducationStart());
    try {
      const response = await axios.get(`/api/suekEducations/get?id=${id}`);
      dispatch(getSuekEducationSuccess(response.data));
    } catch (error) {
      dispatch(getSuekEducationFail(error as string));
    }
  };
};


export const getSuekEducationClear= (): ISuekEducationAction => {
  return {
    type: ActionTypes.GET_SUEK_EDUCATION_CLEAR
  };
};

export const updateSuekEducationSuccess = (suekEducation: ISuekEducation): ISuekEducationAction => {
  return {
      type: ActionTypes.UPDATE_SUEK_EDUCATION_SUCCESS,
      suekEducation
  }
};

export const updateSuekEducationFail = (error: string): ISuekEducationAction => {
  return {
      type: ActionTypes.UPDATE_SUEK_EDUCATION_FAIL,
      error
  }
};

export const updateSuekEducationStart = (): ISuekEducationAction => {
  return {
      type: ActionTypes.UPDATE_SUEK_EDUCATION_START
  }
}

export const updateSuekEducation = (formData: IFormData) => {
  return async (dispatch: Dispatch): Promise<ISuekEducation | null> => {
      dispatch(updateSuekEducationStart());
      try {
          const response = await axios.put(`/api/suekEducations/update`, formData);
          const suekEducation = response.data as ISuekEducation;
          dispatch(updateSuekEducationSuccess(suekEducation));
          return suekEducation;
      } catch (error) {
          dispatch(updateSuekEducationFail(error as string));
          return null;
      }        
  };
};


export const uploadSuekEducationFilesSuccess = (suekEducation: ISuekEducation): ISuekEducationAction => {
  return {
      type: ActionTypes.UPLOAD_SUEK_EDUCATION_FILES_SUCCESS,
      suekEducation
  }
};

export const uploadSuekEducationFilesFail = (error: string): ISuekEducationAction => {
  return {
      type: ActionTypes.UPLOAD_SUEK_EDUCATION_FILES_FAIL,
      error
  }
};

export const uploadSuekEducationFilesStart = (): ISuekEducationAction => {
  return {
      type: ActionTypes.UPLOAD_SUEK_EDUCATION_FILES_START
  }
}

export const uploadSuekEducationFiles = (formData: FormData) => {
  return async (dispatch: Dispatch): Promise<ISuekEducation | null> => {
      dispatch(uploadSuekEducationFilesStart());
      try {
          const response = await axios.post(`/api/suekEducations/uploadFiles`, formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          });
          const suekEducation = response.data as ISuekEducation;
          dispatch(uploadSuekEducationFilesSuccess(suekEducation));
          return suekEducation;
      } catch (error) {
          dispatch(uploadSuekEducationFilesFail(error as string));
          return null;
      }        
  };
};
