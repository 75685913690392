
export enum ActionTypes {
  FETCH_MATERIALS_START,
  FETCH_MATERIALS_SUCCESS,
  FETCH_MATERIALS_FAIL,

  SAVE_MATERIAL_START,
  SAVE_MATERIAL_SUCCESS,
  SAVE_MATERIAL_FAIL,

  UPDATE_MATERIAL_START,
  UPDATE_MATERIAL_SUCCESS,
  UPDATE_MATERIAL_FAIL,

  SET_MATERIAL,

  GET_MATERIAL_START,
  GET_MATERIAL_SUCCESS,
  GET_MATERIAL_FAIL,

  DELETE_MATERIAL_START,
  DELETE_MATERIAL_SUCCESS,
  DELETE_MATERIAL_FAIL,

  DELETE_ATTACHMENT_START,
  DELETE_ATTACHMENT_SUCCESS,
  DELETE_ATTACHMENT_FAIL,

  AUTH_START,
  AUTH_SUCCESS,
  AUTH_FAIL,

  LOGOUT_START,
  LOGOUT_SUCCESS,
  LOGOUT_FAIL,
  
  CLEAR_ERROR,
  
  GET_CURRENT_USER_START,
  GET_CURRENT_USER_SUCCESS,
  GET_CURRENT_USER_FAIL,
  
  SAVE_ACADEMY_EDUCATION_START,
  SAVE_ACADEMY_EDUCATION_SUCCESS,
  SAVE_ACADEMY_EDUCATION_FAIL,

  LIST_ACADEMY_EDUCATION_START,
  LIST_ACADEMY_EDUCATION_SUCCESS,
  LIST_ACADEMY_EDUCATION_FAIL,

  SAVE_UNION_EDUCATION_START,
  SAVE_UNION_EDUCATION_SUCCESS,
  SAVE_UNION_EDUCATION_FAIL,

  GET_UNIONS_START,
  GET_UNIONS_SUCCESS,
  GET_UNIONS_FAIL,
  
  FIND_SUEK_EDUCATION_START,
  FIND_SUEK_EDUCATION_SUCCESS,
  FIND_SUEK_EDUCATION_FAIL,

  GET_SUEK_EDUCATION_START,
  GET_SUEK_EDUCATION_SUCCESS,
  GET_SUEK_EDUCATION_FAIL,
  GET_SUEK_EDUCATION_CLEAR,
  
  UPDATE_SUEK_EDUCATION_START,
  UPDATE_SUEK_EDUCATION_SUCCESS,
  UPDATE_SUEK_EDUCATION_FAIL,

  UPLOAD_SUEK_EDUCATION_FILES_START,
  UPLOAD_SUEK_EDUCATION_FILES_SUCCESS,
  UPLOAD_SUEK_EDUCATION_FILES_FAIL,

  // EducationExpenses START
  EDUCATION_EXPENSES_CREATE_START,
  EDUCATION_EXPENSES_CREATE_SUCCESS,
  EDUCATION_EXPENSES_CREATE_FAIL,

  EDUCATION_EXPENSES_FIND_START,
  EDUCATION_EXPENSES_FIND_SUCCESS,
  EDUCATION_EXPENSES_FIND_FAIL,

  EDUCATION_EXPENSES_GET_START,
  EDUCATION_EXPENSES_GET_SUCCESS,
  EDUCATION_EXPENSES_GET_FAIL,
  
  EDUCATION_EXPENSES_UPDATE_START,
  EDUCATION_EXPENSES_UPDATE_SUCCESS,
  EDUCATION_EXPENSES_UPDATE_FAIL,

  EDUCATION_EXPENSES_DELETE_START,
  EDUCATION_EXPENSES_DELETE_SUCCESS,
  EDUCATION_EXPENSES_DELETE_FAIL,

  EDUCATION_EXPENSE_FILES_UPLOAD_START,
  EDUCATION_EXPENSE_FILES_UPLOAD_SUCCESS,
  EDUCATION_EXPENSE_FILES_UPLOAD_FAIL,
  EDUCATION_EXPENSE_FILES_UPLOAD_PROGRESS,

  EDUCATION_EXPENSES_FILES_DELETE_START,
  EDUCATION_EXPENSES_FILES_DELETE_SUCCESS,
  EDUCATION_EXPENSES_FILES_DELETE_FAIL,

  EDUCATION_EXPENSES_FILES_UPDATE_START,
  EDUCATION_EXPENSES_FILES_UPDATE_SUCCESS,
  EDUCATION_EXPENSES_FILES_UPDATE_FAIL,

  EDUCATION_EXPENSES_SEND_START,
  EDUCATION_EXPENSES_SEND_SUCCESS,
  EDUCATION_EXPENSES_SEND_FAIL,

  EDUCATION_EXPENSES_SEND_TO_NETVISOR_START,
  EDUCATION_EXPENSES_SEND_TO_NETVISOR_SUCCESS,
  EDUCATION_EXPENSES_SEND_TO_NETVISOR_FAIL,
  EDUCATION_EXPENSES_SEND_TO_NETVISOR_CLEAR,

  EDUCATION_EXPENSES_GET_ADDABLE_EDUCATIONS_START,
  EDUCATION_EXPENSES_GET_ADDABLE_EDUCATIONS_SUCCESS,
  EDUCATION_EXPENSES_GET_ADDABLE_EDUCATIONS_FAIL,
  // EducationExpenses END

  // Search start
  SEARCH_GET_SEARCH_USERS_START,
  SEARCH_GET_SEARCH_USERS_SUCCESS,
  SEARCH_GET_SEARCH_USERS_FAIL,
  // Search end

  SAVE_SPORT_MEDICINE_EDUCATION_START,
  SAVE_SPORT_MEDICINE_EDUCATION_SUCCESS,
  SAVE_SPORT_MEDICINE_EDUCATION_FAIL,
}



