import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  withRouter
} from "react-router-dom";

import AcademyEducation from "./containers/AcademyEducation/AcademyEducation";
import Logout from "./containers/Auth/Logout/Logout";
import Home from "./containers/Home/Home";
import Materials from "./containers/Materials/Materials";
import SUEKEducation from "./containers/SUEKEducations/SUEKEducation/SUEKEducation";
import Layout from "./hoc/Layout/Layout";
import { IAppState } from "./interfaces/state/IAppState";
import * as actions from "./store/actions";
import SUEKEducations from "./containers/SUEKEducations/SUEKEducations";
import UnionEducation from "./containers/UnionEducation/UnionEducation";
import { IUser } from "./interfaces/domain";
import { useLoginExpirationHook } from "./hooks/useLoginExpirationHook";
import EducationExpenses from "./containers/EducationExpenses/EducationExpenses";
import EducationExpense from "./containers/EducationExpenses/EducationExpense/EducationExpense";
import AcademyEducationList from "./containers/AcademyEducationList/AcademyEducationList";
import SportMedicineEducation from "./containers/SportMedicineEducation/SportMedicineEducation";

interface AppStateProps {
  user: IUser | null,
  isAuthenticated: boolean;
  logout: boolean;
}

interface AppDispatchProps {
  onGetCurrentUser: () => void;
  onGetSearchUsers: () => void;
}

interface AppProps
  extends AppStateProps,
    AppDispatchProps,
    RouteComponentProps {}

const App: React.FC<AppProps> = ({
  history,
  location,
  onGetCurrentUser,
  isAuthenticated,
  logout,
  user,
  onGetSearchUsers,
}) => {
  const [redirect] = useState(location.pathname);

  useLoginExpirationHook(user);

  useEffect(() => {
    onGetCurrentUser();
  }, [onGetCurrentUser]);

  useEffect(() => {
    if (user && user.permissions.koulutusAdmin) {
      onGetSearchUsers();
    }
  }, [onGetSearchUsers, user]);

  useEffect(() => {
    if (isAuthenticated && redirect !== "/auth") {
      history.replace(redirect);
    }
  }, [isAuthenticated, redirect, history]);

  
  useEffect(() => {
    if(logout) {
      history.push('/')
    }
  }, [logout, history]);

  let app = (
    <Switch>
      <Route
        path="/opistokoulutukset/luo-raportti"
        component={AcademyEducation}
      />
      <Route
          path="/liittokoulutukset/luo-raportti"
          component={UnionEducation}
        />
      <Route
          path="/llk-koulutukset/luo-raportti"
          component={SportMedicineEducation}
        />
      <Route path="/" exact component={Home} />
      <Redirect to="/" />
    </Switch>
  );

  if (isAuthenticated) {
    app = (
      <Switch>
        <Route
          path="/opistokoulutukset/luo-raportti"
          component={AcademyEducation}
        />
        <Route
          path="/opistokoulutukset"
          component={AcademyEducationList}
        />
        <Route
          path="/liittokoulutukset/luo-raportti"
          component={UnionEducation}
        />
        <Route
          path="/llk-koulutukset/luo-raportti"
          component={SportMedicineEducation}
        />
        <Route path="/materials" component={Materials} />
        <Route path="/logout" component={Logout} />
        <Route path="/" exact component={Home} />
        <Route path="/educations" exact component={SUEKEducations} />
        <Route path="/educations/:id" component={SUEKEducation} />
        <Route path="/education-expenses" exact component={EducationExpenses} />
        <Route path="/education-expenses/:id" component={EducationExpense} />
        <Redirect to="/" />
      </Switch>
    );
  }

  return <Layout>{app}</Layout>;
};

const mapStateToProps = (state: IAppState) => {
  return {
    user: state.auth.user,
    isAuthenticated: state.auth.user !== null,
    logout: state.auth.logout
  };
};

const mapDispatchToProps = (dispatch: any): AppDispatchProps => {
  return {
    onGetCurrentUser: () => dispatch(actions.getCurrentUser()),
    onGetSearchUsers: () => dispatch(actions.getSearchUsers()),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
