import { Typography } from "@material-ui/core";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import React, { useContext } from "react";
import EducationExpenseContext from "../../../containers/EducationExpenses/EducationExpense/EducationExpenseContext";
import EducationExpenseSection from "../EducationExpenseSection/EducationExpenseSection";

const EducationExpenseCalculations: React.FC = () => {
  const { educationExpense } = useContext(EducationExpenseContext);

  const {
    total,
    reward,
    dailyAllowance,
    travelFee,
    extraTravelFee,
    otherExpensesTotal,
    multipleEducationFee,
    reward_vat,
    reward_total,
    total_vat,
    calculateVAT,
  } = educationExpense;

  const items = [
    { text: "Palkkio", value: reward, vatValue: reward_vat, totalValue: reward_total },
    { text: "Päivärahat", value: dailyAllowance },
    { text: "Matkapäivät", value: extraTravelFee },
    { text: "Matkakulut", value: travelFee },
    { text: "Usean koulutuksen lisä", value: multipleEducationFee },
    { text: "Muut kulut", value: otherExpensesTotal },
    { text: "Yhteensä", value: total, isBold: true, totalValue: total_vat },
  ];

  return (
    <EducationExpenseSection title="Laskelma">
      {items.map((item) => {
        let style: CSSProperties = {};
        if (item.isBold) {
          style = { fontWeight: "bold" };
        }
        return (
          <Typography style={style} key={item.text}>
            {item.totalValue && calculateVAT && calculateVAT.indexOf("Y") !== -1
              ? item.vatValue
                ? `${item.text}: ${item.totalValue} € (alv: ${item.vatValue} €)`
                : `${item.text}: ${item.totalValue} €`
              : `${item.text}: ${item.value} €`
            }
          </Typography>
        );
      })}
    </EducationExpenseSection>
  );
};

export default EducationExpenseCalculations;
