import './index.css';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { applyMiddleware, combineReducers, compose, createStore, Reducer } from 'redux';
import thunk from 'redux-thunk';

import App from './App';
import { IAppState } from './interfaces/state';
import academyReducer from './store/reducers/academyReducer';
import authReducer from './store/reducers/authReducer';
import materialsReducer from './store/reducers/materialsReducer';
import suekEducationReducer from './store/reducers/suekEducationReducer';
import unionReducer from './store/reducers/unionReducer';
import educationExpenseReducer from './store/reducers/educationExpenseReducer';
import searchReducer from './store/reducers/searchReducer';
import sportMedicineEducationReducer from './store/reducers/sportMedicineEducationReducer';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: any;
  }
}

const composeEnhancers =
  process.env.NODE_ENV === "development"
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    : null || compose;

const rootReducer:Reducer<IAppState> = combineReducers({
  materials: materialsReducer,
  auth: authReducer,
  academy: academyReducer,
  suekEducations: suekEducationReducer,
  union: unionReducer,
  educationExpenses: educationExpenseReducer,
  search: searchReducer,
  sportMedicineEducation: sportMedicineEducationReducer,
});

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

const app = (
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);

ReactDOM.render(app, document.getElementById("root"));
