import React from 'react';
import { TFormElementValue } from '../../../shared/utility';

const InputContext = React.createContext<{
  onAutoUpdate: (inputName: string, value: TFormElementValue, action: string, data?: Object) => Promise<boolean>;
  initDone: boolean;
}>({
  onAutoUpdate: async (inputName, value, action, data) => { return false },
  initDone: false
});

export default InputContext;
