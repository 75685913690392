import List from "@material-ui/core/List";
import FolderIcon from "@material-ui/icons/Folder";
import HomeIcon from "@material-ui/icons/Home";
import ListIcon from "@material-ui/icons/List";
import AssignmentIcon from "@material-ui/icons/Assignment";
import React from "react";

import NavigationItem from "./NavigationItem/NavigationItem";
import { IUser } from "../../../interfaces/domain";

const classes = require("./NavigationItems.module.scss");

interface IProps {
  user: IUser | null;
}

const NavigationItems: React.FC<IProps> = ({ user }) => {
  let items = (
    <List>
      <NavigationItem title="Etusivu" link="/" exact>
        <HomeIcon />
      </NavigationItem>
    </List>
  );

  if (user !== null) {
    const { permissions } = user;
    items = (
      <List>
        <NavigationItem title="Etusivu" link="/" exact>
          <HomeIcon />
        </NavigationItem>
        <NavigationItem title="Materiaalit" link="/materials">
          <FolderIcon />
        </NavigationItem>
        {permissions.suekInstructor && (
          <NavigationItem title="Koulutukset" link="/educations">
            <ListIcon />
          </NavigationItem>
        )}
        {permissions.educationExpense && (
          <NavigationItem title="Luentopalkkio" link="/education-expenses">
            <ListIcon />
          </NavigationItem>
        )}
        {permissions.academyInstructor && (
          <>
            <NavigationItem
              title="Uusi opistokoulutus"
              link="/opistokoulutukset/luo-raportti"
            >
              <AssignmentIcon />
            </NavigationItem>
            <NavigationItem
              title="Opistokoulutukset"
              link="/opistokoulutukset"
            >
              <ListIcon />
            </NavigationItem>
          </>
        )}
        {permissions.unionInstructor && (
          <NavigationItem
            title="Uusi liittokoulutus"
            link="/liittokoulutukset/luo-raportti"
          >
            <AssignmentIcon />
          </NavigationItem>
        )}
        {permissions.llkInstructor && (
          <NavigationItem
            title="Uusi LLK-koulutus"
            link="/llk-koulutukset/luo-raportti"
          >
            <AssignmentIcon />
          </NavigationItem>
        )}
      </List>
    );
  }

  return <div className={classes.NavigationItems}>{items}</div>;
};

export default NavigationItems;
