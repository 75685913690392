import { Typography } from "@material-ui/core";
import React, { useContext } from "react";
import EducationExpenseContext from "../../../containers/EducationExpenses/EducationExpense/EducationExpenseContext";
import EducationExpenseSection from "../EducationExpenseSection/EducationExpenseSection";

const EducationExpenseBasicInformation: React.FC = () => {
  const { educationExpense } = useContext(EducationExpenseContext);
  const { userName, identificationNumber, streetAddress, zip, city } =
    educationExpense;
  return (
    <EducationExpenseSection>
      <Typography>
        {userName} ({identificationNumber})
      </Typography>
      <Typography>{streetAddress}</Typography>
      <Typography>
        {zip} {city}
      </Typography>
    </EducationExpenseSection>
  );
};

export default EducationExpenseBasicInformation;
