import { IAttachment } from './IAttachment';
import { IFormData } from './../../shared/utility';

export enum ESuekEducationStatus {
  OPEN = "OPEN",
  DONE = "DONE"
  
}

export interface ISuekEducation extends IFormData {
  id: number;
  status: ESuekEducationStatus;
  name: string;
  place: string;
  startDate: string;
  endDate: string;
  theme: string[];
  sport: string;
  targetGroupName: string;
  contactPerson: string;
  additionalInfo: string;
  duration: string;
  participantsEstimate: number;
  participantsReality: number;
  subscriber: string;
  lecturerComments: string;
  participantsFeedback: string;
  subscriberFeedback: string;
  attachments: IAttachment[];
}