import { ISportMedicineEducationAction } from '../../interfaces/action/ISportMedicineEducationAction';
import { ISportMedicineEducationState } from '../../interfaces/state/ISportMedicineEducationState';
import { updateObject } from '../../shared/utility';
import { ActionTypes } from '../actions/actionTypes';

type ReducerSignature = (
  state: ISportMedicineEducationState,
  action: ISportMedicineEducationAction,
) => ISportMedicineEducationState;

const initialState: ISportMedicineEducationState = {
  sportMedicineEducation: null,
  loading: false,
  error: null,
};

const saveSportMedicineEducationStart: ReducerSignature = (state, action) => {
  return updateObject(state, { loading: true, error: null });
};

const saveSportMedicineEducationSuccess: ReducerSignature = (state, action) => {
  return updateObject(state, {
    loading: false,
    sportMedicineEducation: action.sportMedicineEducation
  });
};

const saveSportMedicineEducationFail:ReducerSignature = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const reducer = (state: ISportMedicineEducationState = initialState, action: ISportMedicineEducationAction): ISportMedicineEducationState => {
  switch (action.type) {
    case ActionTypes.SAVE_SPORT_MEDICINE_EDUCATION_START:
      return saveSportMedicineEducationStart(state, action);
    case ActionTypes.SAVE_SPORT_MEDICINE_EDUCATION_SUCCESS:
      return saveSportMedicineEducationSuccess(state, action);
    case ActionTypes.SAVE_SPORT_MEDICINE_EDUCATION_FAIL:
      return saveSportMedicineEducationFail(state, action);
    default:
      return state;
  }
};

export default reducer;
