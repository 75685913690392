import { Delete } from "@material-ui/icons";
import React from "react";

import classes from "./PendingFiles.module.scss";

interface IProps {
  pendingFiles: File[];
  onRemove: (index: number) => void;
}

const PendingFiles: React.FC<IProps> = ({ pendingFiles, onRemove }) => {
  if (!pendingFiles || pendingFiles.length === 0) return null;
  return (
    <>
      <p>
        <strong>Latausta odottavat tiedostot</strong>
      </p>
      {pendingFiles.map((file, i) => (
        <div className={classes.PendingFile} key={`${file.name}-${i}`}>
          {file.name}
          <span
            className={classes.PendingFileRemove}
            onClick={() => onRemove(i)}
          >
            <Delete />
          </span>
        </div>
      ))}
    </>
  );
};

export default PendingFiles;
