import {
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import React, { useCallback, useContext, useEffect, useState } from "react";
import EducationExpenseContext from "../../../containers/EducationExpenses/EducationExpense/EducationExpenseContext";
import EducationExpenseSection from "../EducationExpenseSection/EducationExpenseSection";

interface IProps {
  onEducationsChange: (educations: number[]) => Promise<void>;
}

const EducationExpenseEducations: React.FC<IProps> = ({
  onEducationsChange,
}) => {
  const [educationsValue, setEducationsValue] = useState("");
  const { educationOptions, educationExpense, isDisabled } = useContext(
    EducationExpenseContext
  );
  const [isAdding, setIsAdding] = useState(false);
  const [deletingId, setDeletingId] = useState<number | null>(null);

  const { educations } = educationExpense;

  const ids = educations.map((education) => education.id);

  const addEducationHandler = useCallback(
    async (value: number[], isAdd = false) => {
      setIsAdding(true);
      await onEducationsChange(value);
      setIsAdding(false);
    },
    [onEducationsChange]
  );

  useEffect(() => {
    if (educationsValue) {
      const value = [...ids, parseInt(educationsValue)];
      addEducationHandler(value, true);
      setEducationsValue("");
    }
  }, [educationsValue, ids, addEducationHandler]);

  const deleteHandler = async (id: number) => {
    const value = ids.filter((item) => item !== id);
    setDeletingId(id);
    await onEducationsChange(value);
    setDeletingId(null);
  };

  return (
    <EducationExpenseSection title="Koulutukset">
      {educationOptions && educationOptions.length > 0 ? (
        <FormControl margin="normal" style={{ width: "100%" }}>
          <InputLabel>Lisää koulutus</InputLabel>
          <Select
            disabled={isDisabled || isAdding}
            value={educationsValue}
            onChange={(event) => {
              const newValue = event.target.value as string;
              setEducationsValue(newValue);
            }}
          >
            {educationOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.text}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <Typography>Ei lisättäviä koulutuksia.</Typography>
      )}

      {educations.length > 0 && (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nimi</TableCell>
              <TableCell>Paikka</TableCell>
              <TableCell>Ajankohta</TableCell>
              <TableCell>Kesto</TableCell>
              {!isDisabled && <TableCell style={{ width: "1px" }}></TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {educations.map((education) => (
              <TableRow key={education.id}>
                <TableCell>{education.name}</TableCell>
                <TableCell>{education.place}</TableCell>
                <TableCell>
                  {education.startDate} - {education.endDate}
                </TableCell>
                <TableCell>{education.duration}</TableCell>
                {!isDisabled && (
                  <TableCell>
                    {deletingId === education.id ? (
                      <CircularProgress size="1.5rem" />
                    ) : (
                      <Button
                        variant="text"
                        color="default"
                        onClick={() => deleteHandler(education.id)}
                      >
                        Poista
                      </Button>
                    )}
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
      {isAdding && (
        <div style={{ textAlign: "center", marginTop: "1rem" }}>
          <CircularProgress size="1.5rem" />
        </div>
      )}
    </EducationExpenseSection>
  );
};

export default EducationExpenseEducations;
