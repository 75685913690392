const unionEducationContents = [
  "Antidopingasiat",
  "Kilpailumanipulaatio"
];

export const unionEducationContentOptions = unionEducationContents.map(content => {
  return {
    value: content,
    text: content
  };
});


export const UNION_TARGET_GROUPS = {
  ATHLETES: 'Urheilijat',
}

const unionTargetGroups = [
  UNION_TARGET_GROUPS.ATHLETES,
  "Valmentajat",
  "Ohjaajat",
  "Terveydenhuoltohenkilöstö",
  "Lajiliiton edustajat (henkilökunta, luottamushenkilö)",
  "Urheiluseurat"
];

export const unionTargetGroupOptions = unionTargetGroups.map(content => {
  return {
    value: content,
    text: content
  };
});