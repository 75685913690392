import { Button, CircularProgress, Typography } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import axios from "../../axios";
import PendingFiles from "../../components/PendingFiles/PendingFiles";
import CustomDialog from "../../components/UI/CustomDialog/CustomDialog";
import Input, { EInputType } from "../../components/UI/Input/Input";
import {
  academyOptions,
  educationContentOptions,
  targetGroupOptions,
} from "../../data/academy";
import withErrorHandler from "../../hoc/withErrorHandler/withErrorHandler";
import { IAcademyEducation, IUser } from "../../interfaces/domain";
import { IAppState } from "../../interfaces/state";
import {
  defaultInputChangedHandler,
  getFormData,
  IControls,
  IInputConfigOptional,
  validateInput,
} from "../../shared/utility";
import * as actions from "../../store/actions";

const classes = require("./AcademyEducation.module.scss");

export enum EFields {
  name = "name",
  instructor = "instructor",
  instructorEmail = "instructorEmail",
  educationDate = "educationDate",
  academy = "academy",
  targetGroups = "targetGroups",
  targetGroupOther = "targetGroupOther",
  participantsAmount = "participantsAmount",
  educationContents = "educationContents",
  educationContentDescription = "educationContentDescription",
  feedback = "feedback",
  comments = "comments",
  attachments = "attachments",
}

const initControls: IControls = {
  controls: {
    [EFields.name]: {
      type: EInputType.text,
      value: "",
      label: "Koulutuksen nimi",
      validation: {
        required: true,
      },
      valid: false,
    },
    [EFields.instructor]: {
      type: EInputType.text,
      value: "",
      label: "Kouluttaja",
      validation: {
        required: true,
      },
      valid: false,
    },
    [EFields.instructorEmail]: {
      type: EInputType.email,
      value: "",
      label: "Sähköposti",
      validation: {
        required: true,
        email: true,
      },
      valid: false,
    },
    [EFields.educationDate]: {
      type: EInputType.date,
      value: null,
      label: "Koulutuksen päivämäärä",
      validation: {
        required: true,
      },
      valid: false,
    },
    [EFields.academy]: {
      type: EInputType.select,
      value: [],
      label: "Urheiluopisto",
      options: academyOptions,
      multiple: true,
      validation: {
        required: true,
      },
      valid: false,
    },
    [EFields.targetGroups]: {
      type: EInputType.select,
      value: [],
      label: "Kohderyhmät",
      options: targetGroupOptions,
      multiple: true,
      validation: {
        required: true,
        or: ["targetGroupOther"],
      },
      valid: false,
    },
    [EFields.targetGroupOther]: {
      type: EInputType.text,
      value: "",
      label: "Muu kohderyhmä",
      validation: {
        required: true,
        or: ["targetGroups"],
      },
      valid: false,
    },
    [EFields.participantsAmount]: {
      type: EInputType.number,
      value: "",
      label: "Osallistujamäärä",
      validation: {
        required: true,
      },
      valid: false,
    },
    [EFields.educationContents]: {
      type: EInputType.select,
      value: [],
      label: "Koulutuksen sisältö",
      options: educationContentOptions,
      multiple: true,
      validation: {
        required: true,
      },
      valid: false,
    },
    [EFields.educationContentDescription]: {
      type: EInputType.text,
      value: "",
      label: "Kuvaus koulutuksen sisällöstä",
      multiline: true,
      validation: {
        required: true,
      },
      valid: false,
    },
    [EFields.feedback]: {
      type: EInputType.text,
      value: "",
      label: "Palaute",
      multiline: true,
      validation: {
        required: true,
      },
      valid: false,
    },
    [EFields.comments]: {
      type: EInputType.text,
      value: "",
      label: "Muita kommentteja",
      multiline: true,
      validation: {
        required: true,
      },
      valid: false,
    },
    [EFields.attachments]: {
      type: EInputType.dropzone,
      value: "",
      label: "Liitteet",
      validation: {
        required: false,
      },
      valid: true,
    },
  },
  isValid: false,
};

interface IDispatchProps {
  onSaveAcademyEducation: (formData: FormData) => Promise<IAcademyEducation>;
}

interface IStateProps {
  loading: boolean;
  user: IUser | null;
}

interface IProps extends IDispatchProps, IStateProps {}

const AcademyEducation: React.FC<IProps> = ({
  onSaveAcademyEducation,
  user,
  loading,
}) => {
  const [controls, setControls] = useState(initControls);
  const [isOpen, setOpen] = useState(false);
  const [pendingFiles, setPendingFiles] = useState<File[]>([]);

  useEffect(() => {
    if (user !== null) {
      setControls((prevControls) => {
        let newControls = validateInput(
          prevControls.controls,
          "instructor",
          user.name,
          false
        );
        newControls = validateInput(
          newControls.controls,
          "instructorEmail",
          user.email,
          false
        );
        return newControls;
      });
    }
  }, [user]);

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const controlsData = getFormData(controls.controls);

    const formData = new FormData();
    if (pendingFiles && pendingFiles.length > 0) {
      pendingFiles.forEach((file, index) => {
        formData.append(`file_${index + 1}`, file);
      });
      formData.append("fileAmount", pendingFiles.length.toString());
    }
    formData.append("data", JSON.stringify(controlsData));

    const academyEducation = await onSaveAcademyEducation(formData);
    if (academyEducation && academyEducation.id) {
      setOpen(true);
    }
  };

  const onResetForm = () => {
    setControls(initControls);
    setPendingFiles([]);
  };

  const onCloseDialog = () => {
    setOpen(false);
    onResetForm();
  };

  const dropHandler = (files: File[]) => {
    setPendingFiles((prevFiles) => [...prevFiles, ...files]);
  };

  const removePendingFileHandler = (index: number) => {
    setPendingFiles((prevFiles) => {
      const newFiles = [...prevFiles];
      newFiles.splice(index, 1);
      return newFiles;
    });
  };

  const createInput = (inputName: EFields, config?: IInputConfigOptional) => {
    return (
      <Input
        formControl={{
          id: inputName,
          config: {
            ...controls.controls[inputName],
            ...config,
          },
        }}
        changed={(value, controlName: string) =>
          defaultInputChangedHandler(value, controlName, setControls)
        }
      />
    );
  };

  return (
    <React.Fragment>
      <CustomDialog
        title="Kiitos"
        content="Olemme vastaanottaneet koulutuksen tiedot, kiitos."
        buttonText="Sulje"
        onOk={onCloseDialog}
        open={isOpen}
      />
      <Paper elevation={4} className={classes.Paper}>
        <Typography variant="h5" gutterBottom>
          Urheiluopistojen järjestämät koulutukset
        </Typography>
        <Typography variant="subtitle1">
          Täytä lomakkeen kaikki kohdat ja paina Lähetä.
        </Typography>
        <form onSubmit={onSubmit}>
          {createInput(EFields.name)}
          {createInput(EFields.instructor)}
          {createInput(EFields.instructorEmail)}
          {createInput(EFields.educationDate)}
          {createInput(EFields.academy)}
          {createInput(EFields.targetGroups)}
          {createInput(EFields.targetGroupOther)}
          {createInput(EFields.participantsAmount)}
          {createInput(EFields.educationContents)}
          {createInput(EFields.educationContentDescription)}
          {createInput(EFields.feedback)}
          {createInput(EFields.comments)}
          
          <p>Lataa liitteeksi käyttämäsi koulutusmateriaali ja osallistujalista</p>
          {createInput(EFields.attachments, { onDropHandler: dropHandler })}
          <PendingFiles
            pendingFiles={pendingFiles}
            onRemove={removePendingFileHandler}
          />

          {loading ? (
            <CircularProgress />
          ) : (
            <>
              <Button
                color="primary"
                className={classes.Button}
                type="submit"
                disabled={!controls.isValid}
              >
                Lähetä
              </Button>

              <Button className={classes.Button} onClick={onResetForm}>
                Tyhjennä
              </Button>
            </>
          )}
        </form>
      </Paper>
    </React.Fragment>
  );
};

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    loading: state.academy.loading,
    user: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
  return {
    onSaveAcademyEducation: (academyEducation) =>
      dispatch(actions.saveAcademyEducation(academyEducation)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withErrorHandler<IProps>(AcademyEducation, axios));
