import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import React, { ReactNode, useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import axios from '../../axios';
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler';
import { IAppState } from '../../interfaces/state';
import * as actions from '../../store/actions';
import { IAcademyEducation } from '../../interfaces/domain';

const classes = require("./AcademyEducationList.module.scss");

interface IStateProps {
  educations: IAcademyEducation[] | null;
  loading: boolean;
}

interface IDispatchProps {
  onFetchEducations: () => void;
}

interface IProps extends IStateProps, IDispatchProps, RouteComponentProps {}

const AcademyEducationList: React.FC<IProps> = ({
  loading,
  onFetchEducations,
  educations,
  history,
}) => {

  useEffect(() => {
    onFetchEducations();
  }, [onFetchEducations]);


  let materialsElement:ReactNode = null;
  if (loading) {
    materialsElement = <LinearProgress color="secondary" />;
  } else if (educations && educations.length) {
    materialsElement = (
      <Paper elevation={4} className={classes.Paper}>
        <Table className={classes.Table}>
          <TableHead>
            <TableRow>
              <TableCell>Päivämäärä</TableCell>
              <TableCell>Koulutuksen nimi</TableCell>
              <TableCell>Urheiluopisto</TableCell>
              <TableCell>Osallistujamäärä</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {educations.map(item => (
              <TableRow key={item.id}>
                <TableCell>{item.educationDate}</TableCell>
                <TableCell>{item.name}</TableCell>
                <TableCell>{item.academy}</TableCell>
                <TableCell>{item.participantsAmount}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    );
  } else {
    materialsElement = (
      <Paper elevation={4} className={classes.Paper}>
        <Typography gutterBottom component="b">
          Ei opistokoulutuksia.
        </Typography>
      </Paper>
    );
  }

  return (
    <React.Fragment>
      {materialsElement}
    </React.Fragment>
  );
};

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    educations: state.academy.academyEducations,
    loading: state.academy.loading,
  };
};

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
  return {
    onFetchEducations: () => dispatch(actions.findAcademyEducations()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withErrorHandler(AcademyEducationList, axios));
