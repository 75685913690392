import { ISuekEducationAction } from '../../interfaces/action/ISuekEducationAction';
import { ISuekEducationState } from '../../interfaces/state/ISuekEducationState';
import { updateObject } from '../../shared/utility';
import { ActionTypes } from '../actions/actionTypes';

type ReducerSignature = (state: ISuekEducationState, action: ISuekEducationAction) => ISuekEducationState;

const initialState: ISuekEducationState = {
  suekEducations: [],
  suekEducation: null,
  loading: false,
  uploading: false,
  error: null
};

const findSuekEducationsStart:ReducerSignature = (state, action) => {
  return updateObject(state, { loading: true });
};

const findSuekEducationsSuccess:ReducerSignature = (state, action) => {
  return updateObject(state, {
    loading: false,
    suekEducations: action.suekEducations
  });
};

const findSuekEducationsFail:ReducerSignature = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const getSuekEducationStart:ReducerSignature = (state, action) => {
  return updateObject(state, { loading: true });
};

const getSuekEducationSuccess:ReducerSignature = (state, action) => {
  return updateObject(state, {
    loading: false,
    suekEducation: action.suekEducation
  });
};

const getSuekEducationClear:ReducerSignature = (state, action) => {
  return updateObject(state, {
    loading: false,
    suekEducation: null,
    error: null
  });
};

const getSuekEducationFail:ReducerSignature = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const updateSuekEducationStart:ReducerSignature = (state, action) => {
  return updateObject(state, { loading: true });
};

const updateSuekEducationSuccess:ReducerSignature = (state, action) => {
  return updateObject(state, {
    loading: false,
    suekEducation: action.suekEducation
  });
};

const uploadSuekEducationFilesFail:ReducerSignature = (state, action) => {
  return updateObject(state, { uploading: false, error: action.error });
};

const uploadSuekEducationFilesStart:ReducerSignature = (state, action) => {
  return updateObject(state, { uploading: true });
};

const uploadSuekEducationFilesSuccess:ReducerSignature = (state, action) => {
  return updateObject(state, {
    uploading: false,
    suekEducation: action.suekEducation
  });
};

const updateSuekEducationFail:ReducerSignature = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const reducer = (state = initialState, action: ISuekEducationAction): ISuekEducationState => {
  switch (action.type) {
    case ActionTypes.FIND_SUEK_EDUCATION_START:
      return findSuekEducationsStart(state, action);
    case ActionTypes.FIND_SUEK_EDUCATION_SUCCESS:
      return findSuekEducationsSuccess(state, action);
    case ActionTypes.FIND_SUEK_EDUCATION_FAIL:
      return findSuekEducationsFail(state, action);
    case ActionTypes.GET_SUEK_EDUCATION_START:
      return getSuekEducationStart(state, action);
    case ActionTypes.GET_SUEK_EDUCATION_SUCCESS:
      return getSuekEducationSuccess(state, action);
    case ActionTypes.GET_SUEK_EDUCATION_FAIL:
      return getSuekEducationFail(state, action);
    case ActionTypes.GET_SUEK_EDUCATION_CLEAR:
      return getSuekEducationClear(state, action);
    case ActionTypes.UPDATE_SUEK_EDUCATION_START:
      return updateSuekEducationStart(state, action);
    case ActionTypes.UPDATE_SUEK_EDUCATION_SUCCESS:
      return updateSuekEducationSuccess(state, action);
    case ActionTypes.UPDATE_SUEK_EDUCATION_FAIL:
      return updateSuekEducationFail(state, action);
    case ActionTypes.UPLOAD_SUEK_EDUCATION_FILES_START:
      return uploadSuekEducationFilesStart(state, action);
    case ActionTypes.UPLOAD_SUEK_EDUCATION_FILES_SUCCESS:
      return uploadSuekEducationFilesSuccess(state, action);
    case ActionTypes.UPLOAD_SUEK_EDUCATION_FILES_FAIL:
      return uploadSuekEducationFilesFail(state, action);
    default:
      return state;
  }
};

export default reducer;
