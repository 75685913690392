import { IEducationExpenseAction } from "../../interfaces/action/IEducationExpenseAction";
import { IEducationExpense } from "../../interfaces/domain";
import { IEducationExpenseState } from "../../interfaces/state/IEducationExpenseState";
import { updateObject } from "../../shared/utility";
import { ActionTypes } from "../actions/actionTypes";
import { IAttachment } from "./../../interfaces/domain/IAttachment";

type ReducerState = IEducationExpenseState;
type ReducerSignature = (
  state: ReducerState,
  action: IEducationExpenseAction
) => ReducerState;

const initialState: ReducerState = {
  educationExpenses: [],
  educationExpense: null,
  id: null,
  loading: false,
  error: null,
  sendError: null,
  sendLoading: false,
  uploadError: null,
  uploadLoading: false,
  uploadProgress: null,
  uploadSuccess: false,
  attachments: [],
  addableEducations: null,
  addableEducationsError: null,
  addableEducationsLoading: false,
  total: null,
  sendToNetvisorOk: false,
};

const createEducationExpenseStart: ReducerSignature = (state, action) => {
  return updateObject(state, { educationExpense: null, loading: true });
};

const createEducationExpenseSuccess: ReducerSignature = (state, action) => {
  return updateObject(state, {
    loading: false,
    id: action.id,
  });
};

const createEducationExpenseFail: ReducerSignature = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const findEducationExpensesStart: ReducerSignature = (state, action) => {
  return updateObject(state, { loading: true, total: null });
};

const findEducationExpensesSuccess: ReducerSignature = (state, action) => {
  return updateObject(state, {
    loading: false,
    educationExpenses: action.educationExpenses,
    total: action.total,
  });
};

const findEducationExpensesFail: ReducerSignature = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const getEducationExpenseStart: ReducerSignature = (state, action) => {
  return updateObject(state, { loading: true, id: null });
};

const getEducationExpenseSuccess: ReducerSignature = (state, action) => {
  return updateObject(state, {
    loading: false,
    educationExpense: action.educationExpense,
  });
};

const getEducationExpenseFail: ReducerSignature = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const updateEducationExpenseStart: ReducerSignature = (state, action) => {
  return updateObject(state, {
    // loading: true,
    error: null,
  });
};

const updateEducationExpenseSuccess: ReducerSignature = (state, action) => {
  return updateObject(state, {
    loading: false,
    educationExpense: action.educationExpense,
  });
};

const updateEducationExpenseFail: ReducerSignature = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const deleteEducationExpenseStart: ReducerSignature = (state, action) => {
  return updateObject(state, { loading: true });
};

const deleteEducationExpenseSuccess: ReducerSignature = (state, action) => {
  const educationExpense = action.educationExpense;
  let educationExpenses = null;
  if (educationExpense) {
    educationExpenses = state.educationExpenses.filter(
      (item) => item.id !== educationExpense.id
    );
  } else {
    educationExpenses = [...state.educationExpenses];
  }

  return updateObject(state, {
    educationExpenses,
    loading: false,
  });
};

const deleteEducationExpenseFail: ReducerSignature = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const uploadStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, uploadError: null, uploadLoading: true };
};

const uploadSuccess: ReducerSignature = (state, action): ReducerState => {
  const educationExpense: IEducationExpense = {
    ...(state.educationExpense as IEducationExpense),
    attachments: action.attachments as IAttachment[],
  };

  return {
    ...state,
    uploadSuccess: true,
    uploadLoading: false,
    uploadProgress: null,
    educationExpense,
  };
};

const uploadProgress: ReducerSignature = (state, action): ReducerState => {
  let uploadProgress = null;
  if (action.progress && action.progress >= 0 && action.progress < 100) {
    uploadProgress = action.progress;
  }

  return {
    ...state,
    uploadProgress,
  };
};

const uploadFail: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    uploadSuccess: false,
    uploadLoading: false,
    uploadProgress: null,
    uploadError: action.error || null,
  };
};

const deleteEducationExpenseFileStart: ReducerSignature = (state, action) => {
  return updateObject(state, {});
};

const deleteEducationExpenseFileSuccess: ReducerSignature = (state, action) => {
  let educationExpense = state.educationExpense;
  if (action.educationExpense) {
    educationExpense = { ...action.educationExpense };
  }

  return updateObject(state, {
    educationExpense,
    loading: false,
  });
};

const deleteEducationExpenseFileFail: ReducerSignature = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const updateEducationExpenseFileStart: ReducerSignature = (state, action) => {
  return updateObject(state, {
    // loading: true,
  });
};

const updateEducationExpenseFileSuccess: ReducerSignature = (state, action) => {
  let educationExpense = state.educationExpense;
  if (action.educationExpense) {
    educationExpense = { ...action.educationExpense };
  }
  return updateObject(state, {
    educationExpense,
    loading: false,
  });
};

const updateEducationExpenseFileFail: ReducerSignature = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const sendStart: ReducerSignature = (state, action) => {
  return updateObject(state, {
    sendError: null,
    sendLoading: true,
  });
};

const sendSuccess: ReducerSignature = (state, action) => {
  let educationExpense = { ...(state.educationExpense as IEducationExpense) };
  if (educationExpense && action.educationExpense) {
    educationExpense.invoicedBy = action.educationExpense.invoicedBy;
    educationExpense.invoicedDate = action.educationExpense.invoicedDate;
    educationExpense.confirmedBy = action.educationExpense.confirmedBy;
    educationExpense.confirmedDate = action.educationExpense.confirmedDate;
    educationExpense.status = action.educationExpense.status;
  }

  return updateObject(state, {
    sendLoading: false,
    educationExpense,
  });
};

const sendFail: ReducerSignature = (state, action) => {
  return updateObject(state, { sendLoading: false, sendError: action.error });
};

const sendToNetvisorStart: ReducerSignature = (state, action) => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
};

const sendToNetvisorSuccess: ReducerSignature = (state, action) => {
  let educationExpenses = [...(state.educationExpenses as IEducationExpense[])];
  if (
    educationExpenses &&
    action.educationExpenses &&
    action.educationExpenses.length
  ) {
    action.educationExpenses.forEach((educationExpense) => {
      const educationExpense2 = educationExpenses.find(
        (ee) => ee.id === educationExpense.id
      );
      if (educationExpense2) {
        educationExpense2.status = educationExpense.status;
      }
    });
  }

  return updateObject(state, {
    loading: false,
    educationExpenses,
    sendToNetvisorOk: true,
  });
};

const sendToNetvisorFail: ReducerSignature = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const sendToNetvisorClear: ReducerSignature = (state, action) => {
  return updateObject(state, { sendToNetvisorOk: false });
};

const getAddableEducationsStart: ReducerSignature = (state, action) => {
  return updateObject(state, {
    addableEducations: null,
    addableEducationsLoading: true,
  });
};

const getAddableEducationsSuccess: ReducerSignature = (state, action) => {
  return updateObject(state, {
    addableEducations: action.addableEducations,
    addableEducationsLoading: false,
  });
};

const getAddableEducationsFail: ReducerSignature = (state, action) => {
  return updateObject(state, {
    addableEducationsLoading: false,
    addableEducationsError: action.error,
  });
};

const reducer = (
  state = initialState,
  action: IEducationExpenseAction
): ReducerState => {
  switch (action.type) {
    case ActionTypes.EDUCATION_EXPENSES_CREATE_START:
      return createEducationExpenseStart(state, action);
    case ActionTypes.EDUCATION_EXPENSES_CREATE_SUCCESS:
      return createEducationExpenseSuccess(state, action);
    case ActionTypes.EDUCATION_EXPENSES_CREATE_FAIL:
      return createEducationExpenseFail(state, action);
    case ActionTypes.EDUCATION_EXPENSES_FIND_START:
      return findEducationExpensesStart(state, action);
    case ActionTypes.EDUCATION_EXPENSES_FIND_SUCCESS:
      return findEducationExpensesSuccess(state, action);
    case ActionTypes.EDUCATION_EXPENSES_FIND_FAIL:
      return findEducationExpensesFail(state, action);
    case ActionTypes.EDUCATION_EXPENSES_GET_START:
      return getEducationExpenseStart(state, action);
    case ActionTypes.EDUCATION_EXPENSES_GET_SUCCESS:
      return getEducationExpenseSuccess(state, action);
    case ActionTypes.EDUCATION_EXPENSES_GET_FAIL:
      return getEducationExpenseFail(state, action);
    case ActionTypes.EDUCATION_EXPENSES_UPDATE_START:
      return updateEducationExpenseStart(state, action);
    case ActionTypes.EDUCATION_EXPENSES_UPDATE_SUCCESS:
      return updateEducationExpenseSuccess(state, action);
    case ActionTypes.EDUCATION_EXPENSES_UPDATE_FAIL:
      return updateEducationExpenseFail(state, action);
    case ActionTypes.EDUCATION_EXPENSES_DELETE_START:
      return deleteEducationExpenseStart(state, action);
    case ActionTypes.EDUCATION_EXPENSES_DELETE_SUCCESS:
      return deleteEducationExpenseSuccess(state, action);
    case ActionTypes.EDUCATION_EXPENSES_DELETE_FAIL:
      return deleteEducationExpenseFail(state, action);
    case ActionTypes.EDUCATION_EXPENSE_FILES_UPLOAD_START:
      return uploadStart(state, action);
    case ActionTypes.EDUCATION_EXPENSE_FILES_UPLOAD_SUCCESS:
      return uploadSuccess(state, action);
    case ActionTypes.EDUCATION_EXPENSE_FILES_UPLOAD_FAIL:
      return uploadFail(state, action);
    case ActionTypes.EDUCATION_EXPENSE_FILES_UPLOAD_PROGRESS:
      return uploadProgress(state, action);
    case ActionTypes.EDUCATION_EXPENSES_FILES_DELETE_START:
      return deleteEducationExpenseFileStart(state, action);
    case ActionTypes.EDUCATION_EXPENSES_FILES_DELETE_SUCCESS:
      return deleteEducationExpenseFileSuccess(state, action);
    case ActionTypes.EDUCATION_EXPENSES_FILES_DELETE_FAIL:
      return deleteEducationExpenseFileFail(state, action);
    case ActionTypes.EDUCATION_EXPENSES_FILES_UPDATE_START:
      return updateEducationExpenseFileStart(state, action);
    case ActionTypes.EDUCATION_EXPENSES_FILES_UPDATE_SUCCESS:
      return updateEducationExpenseFileSuccess(state, action);
    case ActionTypes.EDUCATION_EXPENSES_FILES_UPDATE_FAIL:
      return updateEducationExpenseFileFail(state, action);
    case ActionTypes.EDUCATION_EXPENSES_SEND_START:
      return sendStart(state, action);
    case ActionTypes.EDUCATION_EXPENSES_SEND_SUCCESS:
      return sendSuccess(state, action);
    case ActionTypes.EDUCATION_EXPENSES_SEND_FAIL:
      return sendFail(state, action);
    case ActionTypes.EDUCATION_EXPENSES_SEND_TO_NETVISOR_START:
      return sendToNetvisorStart(state, action);
    case ActionTypes.EDUCATION_EXPENSES_SEND_TO_NETVISOR_SUCCESS:
      return sendToNetvisorSuccess(state, action);
    case ActionTypes.EDUCATION_EXPENSES_SEND_TO_NETVISOR_FAIL:
      return sendToNetvisorFail(state, action);
    case ActionTypes.EDUCATION_EXPENSES_SEND_TO_NETVISOR_CLEAR:
      return sendToNetvisorClear(state, action);
    case ActionTypes.EDUCATION_EXPENSES_GET_ADDABLE_EDUCATIONS_START:
      return getAddableEducationsStart(state, action);
    case ActionTypes.EDUCATION_EXPENSES_GET_ADDABLE_EDUCATIONS_SUCCESS:
      return getAddableEducationsSuccess(state, action);
    case ActionTypes.EDUCATION_EXPENSES_GET_ADDABLE_EDUCATIONS_FAIL:
      return getAddableEducationsFail(state, action);
    default:
      return state;
  }
};

export default reducer;
