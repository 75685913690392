import { updateObject } from '../../shared/utility';
import { ActionTypes } from '../actions/actionTypes';
import { IUnionEducationAction } from './../../interfaces/action/IUnionEducationAction';
import { IUnionEducationState } from './../../interfaces/state/IUnionEducationState';

type ReducerSignature = (
  state: IUnionEducationState,
  action: IUnionEducationAction
) => IUnionEducationState;

const initialState: IUnionEducationState = {
  unionEducation: null,
  loading: false,
  error: null,
  unions: null,
  unionsLoading: false,
  unionsError: null
};

const saveUnionEducationStart: ReducerSignature = (state, action) => {
  return updateObject(state, { loading: true });
};

const saveUnionEducationSuccess: ReducerSignature = (state, action) => {
  return updateObject(state, {
    loading: false,
    unionEducation: action.unionEducation
  });
};

const saveUnionEducationFail:ReducerSignature = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const getUnionsStart: ReducerSignature = (state, action) => {
  return updateObject(state, { unionsLoading: true, unionsError: null });
};

const getUnionsSuccess: ReducerSignature = (state, action) => {
  return updateObject(state, {
    unionsLoading: false,
    unionsError: null,
    unions: action.unions
  });
};

const getUnionsFail:ReducerSignature = (state, action) => {
  return updateObject(state, { unionsLoading: false, unionsError: action.error });
};

const reducer = (state:IUnionEducationState = initialState, action: IUnionEducationAction): IUnionEducationState => {
  switch (action.type) {
    case ActionTypes.SAVE_UNION_EDUCATION_START:
      return saveUnionEducationStart(state, action);
    case ActionTypes.SAVE_UNION_EDUCATION_SUCCESS:
      return saveUnionEducationSuccess(state, action);
    case ActionTypes.SAVE_UNION_EDUCATION_FAIL:
      return saveUnionEducationFail(state, action);
    case ActionTypes.GET_UNIONS_START:
      return getUnionsStart(state, action);
    case ActionTypes.GET_UNIONS_SUCCESS:
      return getUnionsSuccess(state, action);
    case ActionTypes.GET_UNIONS_FAIL:
      return getUnionsFail(state, action);
    default:
      return state;
  }
};

export default reducer;
