import { ISuekEducation } from './ISuekEducation';
import { IAttachment } from "./IAttachment";

export enum EEducationExpenseStatus {
  NEW = "NEW",
  SENT = "SENT",
  CONFIRMED = "CONFIRMED",
  SENT_TO_NV = "SENT_TO_NV",
  SEND_TO_NV_FAILED = "SEND_TO_NV_FAILED",
}

export enum EEducationExpenseType {
  EDUCATION = 'EDUCATION',
  AUDITING = 'AUDITING',
}

export interface IEducationExpense {
  id: string;
  createdOn: string;
  updatedOn: string;
  createdBy: number;
  updatedBy: number;
  type: EEducationExpenseType;
  user_id: number;
  travelStartDate: string;
  travelEndDate: string;
  travelStartDateString: string;
  travelEndDateString: string;

  travelHours: number;
	travelMinutes: number;
	extraTravelDays: number;
	extraTravelFee: number;
	pricesVersion: string;

  userName: string;
  streetAddress: string;
	zip: string;
	city: string;
	identificationNumber: string;

  educations: ISuekEducation[];
  reward: number;
  otherExpensesTotal: number;
  total: number;

  travelKm: number;
  travelFee: number;
  travelFeePrice: number;
  dailyAllowance: number;
  travelRoute: string;

  invoicedBy: string;
  invoicedDate: string;
  reviewedBy: string;
  reviewedDate: string;
  confirmedBy: string;
  confirmedDate: string;
  travelType: string;
  travelTypeOther: string;

  passengers: string;
  attachments: IAttachment[];
  
  status: EEducationExpenseStatus;
  statusString: string;

  errors: string[];

  calculateVAT: string[];
  multipleEducationFee: number;
  reward_vat: number,
  reward_total: number;
  total_vat: number;
  costCenter: string;
}
