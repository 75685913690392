import React, { ReactNode } from "react";

import { Typography, Paper } from "@material-ui/core";

interface IProps {
  title?: string;
  children: ReactNode;
}

const EducationExpenseSection: React.FC<IProps> = ({ title, children }) => {
  return (
    <section style={{ marginBottom: "1rem" }}>
      {title && (
        <Typography variant="h5" gutterBottom>
          {title}
        </Typography>
      )}

      <Paper elevation={4} style={{ padding: "1rem" }}>
        {children}
      </Paper>
    </section>
  );
};

export default EducationExpenseSection;
