import { IAttachment } from './../interfaces/domain/IAttachment';
import origAxios from 'axios';
import axios from '../axios';

interface ICreateResponse {
  attachment: IAttachment;
  location: string;
}

export interface ICreateAttachment {
  attachment: IAttachment;
  location: string;
  file: IAttachmentFile;
}

export interface IAttachmentFile extends File {
  id?: number;
  progress?: number;
}

export const createAttachment = async (file: IAttachmentFile): Promise<ICreateAttachment>  => {
  const data = { fileName: file.name, contentType: file.type, size: file.size };
  const resp = await axios.post<ICreateResponse>(`/json/attachments/create`, JSON.stringify(data))
  const respData = resp.data;
  return {
    location: respData.location,
    attachment: respData.attachment,
    file
  };
}

export const uploadFile = async (createAttachment: ICreateAttachment, onProgress: (progress: number, id?: number) => void): Promise<IAttachment> => {
  console.log(createAttachment.file);

  await origAxios.put(createAttachment.location, createAttachment.file, {
    onUploadProgress: (progress) => {
      onProgress(Math.round(progress.loaded / progress.total * 100), createAttachment.file.id)
    }
  });

  return createAttachment.attachment;
}