import { Typography } from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import EducationExpenseSection from '../EducationExpenseSection/EducationExpenseSection';

interface IProps {
  title: string;
  username: string;
  date: string;
  dateFormat?: string;
}

const EducationExpenseDate: React.FC<IProps> = ({
  title, username, date, dateFormat = "DD.MM.yyyy",
}) => {
  return (
    <EducationExpenseSection title={title}>
      <Typography>
        {username}
      </Typography>
      <Typography>
        {moment(date).format(dateFormat)}
      </Typography>
    </EducationExpenseSection>
  );
};

export default EducationExpenseDate;
