import MomentUtils from "@date-io/moment";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import {
  KeyboardDatePicker,
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import "moment/locale/fi";
import React, { useContext, useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import {
  IFormControl,
  TFormControlChanged,
  TFormElementValue,
} from "../../../shared/utility";
import InputContext from "./InputContext";
// import Checkbox from "../../../common-components/Checkbox/Checkbox";

const classes = require("./Input.module.scss");

interface InputProps {
  formControl: IFormControl;
  changed: TFormControlChanged;
  data?: Object;
  updateAction?: string;
}

export enum EInputType {
  text = "text",
  number = "number",
  email = "email",
  date = "date",
  select = "select",
  dropzone = "dropzone",
  datetime = "datetime",
  checkbox = "checkbox",
}

const Input: React.FC<InputProps> = ({
  formControl,
  changed,
  data,
  updateAction,
}) => {
  const [focusValue, setFocusValue] = useState<TFormElementValue | null>(null);

  const { onAutoUpdate, initDone } = useContext(InputContext);

  const [initValue, setInitValue] = useState(formControl.config.value);

  const { id, config } = formControl;
  const {
    value,
    type,
    valid,
    touched,
    label,
    multiline,
    disabled,
    multiple,
    options,
    onBlur,
    onBlurModifyValue,
    onDropHandler
  } = config;

  useEffect(() => {
    if (initDone) {
      setInitValue(formControl.config.value);
    }

    // eslint-disable-next-line
  }, [initDone, setInitValue]);

  const autoUpdateHandler = (newValue: TFormElementValue) => {
    if (focusValue === newValue) return;
    if (onBlur) {
      onBlur(newValue, id);
    }

    if (onBlurModifyValue) {
      newValue = onBlurModifyValue(newValue, id);
    }

    if (initValue !== newValue) {
      if (updateAction && onAutoUpdate) {
        onAutoUpdate(id, newValue, updateAction, data); // Maybe needs some checks here if context missing.
      }
      setInitValue(newValue);
    }
  };

  let input = null;
  switch (type) {
    case EInputType.text:
    case EInputType.number:
    case EInputType.email:
      input = (
        <TextField
          error={!valid && touched}
          type={type}
          label={label}
          className={classes.TextField}
          margin="normal"
          value={value}
          onChange={(event) => changed(event.target.value, id)}
          multiline={multiline}
          disabled={disabled}
          onBlur={() => autoUpdateHandler(value)}
          onFocus={() => setFocusValue(value)}
        />
      );
      break;
    case EInputType.date:
      input = (
        <MuiPickersUtilsProvider utils={MomentUtils} locale="fi">
          <KeyboardDatePicker
            error={!valid && touched}
            cancelLabel="Peruuta"
            margin="normal"
            className={classes.TextField}
            label={label}
            format="DD.MM.YYYY"
            value={value}
            onChange={(date) => changed(date, id)}
            onAccept={(date) => autoUpdateHandler(date)}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            onBlur={() => autoUpdateHandler(value)}
            onFocus={() => setFocusValue(value)}
            invalidDateMessage="Virheellinen päivämäärä"
            disabled={disabled}
          />
        </MuiPickersUtilsProvider>
      );
      break;
    case EInputType.datetime:
      input = (
        <MuiPickersUtilsProvider utils={MomentUtils} locale="fi">
          <KeyboardDateTimePicker
            error={!valid && touched}
            cancelLabel="Peruuta"
            margin="normal"
            className={classes.TextField}
            label={label}
            format="DD.MM.YYYY HH:mm"
            value={value}
            onChange={(date) => changed(date, id)}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            ampm={false}
            onAccept={(date) => autoUpdateHandler(date)}
            onBlur={() => autoUpdateHandler(value)}
            onFocus={() => setFocusValue(value)}
            invalidDateMessage="Virheellinen päivämäärä"
            disabled={disabled}
          />
        </MuiPickersUtilsProvider>
      );
      break;
    case EInputType.select:
      input = (
        <FormControl
          className={classes.TextField}
          margin="normal"
          error={!valid && touched}
        >
          <InputLabel>{formControl.config.label}</InputLabel>
          <Select
            disabled={disabled}
            value={value}
            multiple={multiple}
            onChange={(event) => {
              const newValue = event.target.value as string | string[];
              changed(newValue, id);
              autoUpdateHandler(newValue);
            }}
          >
            {options &&
              options.map((option) => {
                return (
                  <MenuItem key={option.value} value={option.value}>
                    {option.text}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      );
      break;
    case EInputType.dropzone:
      input = (
        <Dropzone onDrop={onDropHandler}>
          {({ getRootProps, getInputProps, isDragActive }) => {
            const classNames = [classes.Dropzone];
            if (isDragActive) {
              classNames.push(classes.DropzoneDragActive);
            }
            return (
              <div {...getRootProps()} className={classNames.join(" ")}>
                <input {...getInputProps()} />
                <p>
                  {isDragActive
                    ? "Tiedosto(t) ladataan kun päästät irti."
                    : "Lataa liitteet painamalla tästä tai pudottamalla tiedostot."}
                </p>
              </div>
            );
          }}
        </Dropzone>
      );
      break;
    case EInputType.checkbox:
      input = (
        <>
          {options ? options.map(option => {
            const newValue = value as string[];
            return (
              <FormControlLabel
                key={option.text}
                label={option.text}
                control={
                  <Checkbox
                    name={id}
                    value={option.value}
                    checked={newValue.indexOf(option.value) !== -1}
                    onChange={(event) => {
                      let val = [event.target.value as string];
                      if (newValue.indexOf(option.value) !== -1) {
                        val = [];
                      }
                      changed(val, id);
                      autoUpdateHandler(val);
                    }}
                    disabled={disabled}
                  />
                }
              />
            )
          }
          ) : null}
        </>
      );
      break;
    default:
      return null;
  }
  return input;
};

export default React.memo(Input, (prevProps, nextProps) => {
  const prevConfig = prevProps.formControl.config;
  const nextConfig = nextProps.formControl.config;
  return (
    prevConfig.value === nextConfig.value &&
    prevConfig.options === nextConfig.options &&
    prevConfig.touched === nextConfig.touched &&
    prevConfig.valid === nextConfig.valid &&
    prevConfig.disabled === nextConfig.disabled
  );
});
