import React, { useCallback } from 'react';
import Input from '../components/UI/Input/Input';
import { defaultInputChangedHandler, IControls, IInputConfigOptional } from '../shared/utility';

export type TCreateInput = (inputName: string, options?: IInputConfigOptional) => JSX.Element;

export const useCreateInput = (inputs: IControls, setInputs: React.Dispatch<React.SetStateAction<IControls>>, commonOptions?: IInputConfigOptional) => {

  const createInput: TCreateInput = useCallback((inputName: string, options?: IInputConfigOptional) => {
    const item = inputs.controls[inputName];
    return (
      <Input
        formControl={{
          id: inputName,
          config: {
            ...commonOptions,
            ...item,
            ...options,
          }
        }}
        changed={(value) => defaultInputChangedHandler(value, inputName, setInputs)}
      />
    );
  }, [commonOptions, setInputs, inputs.controls]);

  return createInput;
}
