import { Fab, LinearProgress, Paper } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { Alert } from "@material-ui/lab";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router";

import EducationExpensesList from "../../components/EducationExpenses/EducationExpensesList/EducationExpensesList";
import EducationExpensesSearch from "../../components/EducationExpenses/EducationExpensesSearch/EducationExpensesSearch";
import CustomDialog from "../../components/UI/CustomDialog/CustomDialog";
import { IEducationExpense, EEducationExpenseStatus } from "../../interfaces/domain";
import { IAppState, IAuthState } from "../../interfaces/state";
import { IEducationExpenseState } from "../../interfaces/state/IEducationExpenseState";
import { IFormData } from "../../shared/utility";
import * as actions from "../../store/actions";
import classes from "./EducationExpenses.module.scss";

const EducationExpenses: React.FC<RouteComponentProps> = ({ history }) => {
  const dispatch = useDispatch();

  const [deleteEducationExpense, setDeleteEducationExpense] =
    useState<IEducationExpense | null>(null);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);

  const { loading, educationExpenses, id, error, total, sendToNetvisorOk } = useSelector<
    IAppState,
    IEducationExpenseState
  >((state) => state.educationExpenses);

  const { user } = useSelector<IAppState, IAuthState>((state) => state.auth);

  const isAdmin = (user && user.permissions.koulutusAdmin) || false;

  useEffect(() => {
    if (sendToNetvisorOk) {
      setSelectedItems([]);
      dispatch(actions.sendToNetvisorClear());
    }
  }, [sendToNetvisorOk, dispatch])

  const searchHandler = useCallback(
    (data: IFormData) => {
      dispatch(actions.findEducationExpenses(data));
    },
    [dispatch]
  );

  const sendToNetvisorHandler = useCallback(() => {
    dispatch(actions.sendToNetvisor(selectedItems));
  }, [selectedItems, dispatch]);

  const editHandler = useCallback(
    (id: string) => {
      history.push(`/education-expenses/${id}`);
    },
    [history]
  );

  useEffect(() => {
    if (id) {
      editHandler(id);
    }
  }, [editHandler, id]);

  const deleteHandler = (educationExpense: IEducationExpense | null) => {
    if (educationExpense) {
      dispatch(actions.deleteEducationExpense(educationExpense));
      setDeleteEducationExpense(null);
    }
  };

  const toggleAllHandler = () => {
    if (educationExpenses) {
      const ids = educationExpenses.filter((item) =>
        [EEducationExpenseStatus.CONFIRMED, EEducationExpenseStatus.SEND_TO_NV_FAILED].includes(item.status)
      ).map((item) => item.id);
      setSelectedItems((items) =>
        items.length === ids.length ? [] : [...ids]
      );
    }
  };

  const toggleHandler = (id: string) => {
    setSelectedItems((items) => {
      const newItems = [...items];
      const index = items.indexOf(id);
      if (index !== -1) {
        newItems.splice(index, 1);
      } else {
        newItems.push(id);
      }
      return newItems;
    });
  };

  return (
    <>
      <CustomDialog
        title="Poista luentopalkkio"
        content="Haluatko varmasti poistaa luentopalkkion?"
        buttonText="Poista"
        onCancel={() => setDeleteEducationExpense(null)}
        onOk={() => deleteHandler(deleteEducationExpense)}
        open={!!deleteEducationExpense}
      />
      <Paper elevation={4}>
        <EducationExpensesSearch
          onSearch={searchHandler}
          disableNetvisor={selectedItems.length === 0}
          isAdmin={isAdmin}
          onSendToNetvisor={sendToNetvisorHandler}
        />

        {error && <Alert severity="error">{error}</Alert>}

        {loading ? (
          <LinearProgress color="primary" />
        ) : (
          <EducationExpensesList
            educationExpenses={educationExpenses}
            isAdmin={isAdmin}
            selectedItems={selectedItems}
            onDelete={setDeleteEducationExpense}
            onEdit={editHandler}
            onToggle={toggleHandler}
            onToggleAll={toggleAllHandler}
            total={total}
          />
        )}
        <Fab
          onClick={() => dispatch(actions.createEducationExpense())}
          color="primary"
          aria-label="add"
          className={classes.AddButton}
        >
          <AddIcon />
        </Fab>
      </Paper>
    </>
  );
};

export default EducationExpenses;
