let hostname;
if(window && window.location) {
  hostname = window.location.hostname;
}
const isDevelopment = hostname && (hostname === 'localhost' || hostname.startsWith('192.168'));

let loginUiUrl = '';
if (isDevelopment) {
  loginUiUrl = `http://${hostname}:3002/login?continue=${encodeURIComponent('http://localhost:3000')}`;
} else {
  loginUiUrl = `https://passi.suek.fi/accounts/login?continue=${encodeURIComponent('https://koulutus.suek.fi')}`;
}

export { loginUiUrl };