import Typography from "@material-ui/core/Typography";
import React from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch, RouteComponentProps } from "react-router-dom";

import MaterialsEdit from "./MaterialsEdit/MaterialsEdit";
import MaterialsList from "./MaterialsList/MaterialsList";
import { IAppState } from "../../interfaces/state";

interface IStateProps {
  isAdmin: boolean;
}

interface IProps extends IStateProps, RouteComponentProps {}

const Materials: React.FC<IProps> = ({ match, isAdmin }) => {
  let routes = (
    <Switch>
      <Route path={match.path + "/"} exact component={MaterialsList} />
      <Redirect to="/" />
    </Switch>
  );

  if (isAdmin) {
    routes = (
      <Switch>
        <Route path={match.path + "/add"} component={MaterialsEdit} />
        <Route path={match.path + "/:id"} component={MaterialsEdit} />
        <Route path={match.path + "/"} exact component={MaterialsList} />
      </Switch>
    );
  }

  return (
    <React.Fragment>
      <Typography variant="h4" gutterBottom>
        Materiaalit
      </Typography>
      {routes}
    </React.Fragment>
  );
};

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    isAdmin: state.auth.user ? state.auth.user.permissions.koulutusAdmin : false
  };
};

export default connect(mapStateToProps)(Materials);
