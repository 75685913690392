import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { isMoment } from 'moment';
import React from 'react';

import Input, { EInputType } from '../components/UI/Input/Input';
import { IAttachment } from '../interfaces/domain';

export function updateObject<T>(
  oldObject: T,
  updatedProperties: Partial<T>
): T {
  return {
    ...oldObject,
    ...updatedProperties
  };
}

export type TFormElementValue = string | MaterialUiPickersDate | string[] | IOption[] | number | IAttachment[] | number[];

export interface IOption {
  value: string;
  text: string;
}

export interface IInputConfigOptional {
  validation?: IRules;
  touched?: boolean;
  disabled?: boolean;
  multiline?: boolean;
  multiple?: boolean;
  options?: IOption[];
  onDropHandler?: (files: File[]) => void;
  onBlurModifyValue?: (value: TFormElementValue, controlName: string) => TFormElementValue;
  onBlur?: TFormControlChanged;
}

export interface IInputConfig extends IInputConfigOptional {
  type: EInputType;
  label: string;
  value: TFormElementValue;
  valid: boolean;
}

export interface IFormControl {
  id: string;
  config: IInputConfig;
}

export interface IFormData {
  [key: string]: TFormElementValue;
}

interface IRules {
  minLength?: number;
  maxLength?: number;
  email?: boolean;
  required?: boolean;
  or?: string[];
}

export interface IControl {
  [key: string]: IInputConfig;
}

export interface IControls {
  controls: IControl;
  isValid: boolean;
}

type TSetControls = React.Dispatch<React.SetStateAction<IControls>>;
export type TFormControlChanged = (value: TFormElementValue, controlName: string) => void;

export const checkValidity = (value: any, rules?: IRules, type?: EInputType) => {
  let isValid = true;

  if (!rules) {
    return true;
  }

  if (rules.required) {
    if (type === "date") {
      isValid = isMoment(value) && isValid;
    } else if (typeof value === "object") {
      isValid = value.length > 0 && isValid;
    } else if(typeof value === "string") {
      isValid = value.trim() !== "" && isValid;
    } else if(typeof value === "number") {
      isValid = value.toString().trim() !== "" && isValid;
    }
  }
  if (rules.minLength) {
    isValid = value.length >= rules.minLength && isValid;
  }
  if (rules.maxLength) {
    isValid = value.length <= rules.maxLength && isValid;
  }
  if (rules.email) {
    isValid = isValidEmail(value) && isValid;
  }

  return isValid;
};

export const isValidEmail = (email: string) => {
  return /\S+@\S+\.\S+/.test(email);
};

export const validateInput = (
  controls: IControl,
  controlName: string,
  value: TFormElementValue,
  disabled: boolean
) => {
  const validation = controls[controlName].validation || {};

  let isValid = checkValidity(value, validation, controls[controlName].type);

  let updatedControls = { ...controls };

  if (validation.or) {
    for (let orControlName of validation.or) {
      const control = controls[orControlName];
      isValid =
        isValid ||
        checkValidity(
          control.value,
          control.validation,
          control.type
        );

      updatedControls = updateObject(updatedControls, {
        [orControlName]: updateObject(updatedControls[orControlName], {
          value: updatedControls[orControlName].value,
          valid: isValid,
          touched: true,
          disabled
        })
      });
    }
  }

  updatedControls = updateObject(updatedControls, {
    [controlName]: updateObject(updatedControls[controlName], {
      value,
      valid: isValid,
      touched: true,
      disabled
    })
  });

  return {
    controls: updatedControls,
    isValid: validateControls(updatedControls)
  };
};

export const disableInputs = (
  disabled: boolean,
  setControls: TSetControls,
): void => {
  setControls((prevState: IControls) => {
    let newState = { ...prevState };
    let controls = { ...newState.controls };
    for (let key in controls) {
      controls[key].disabled = disabled;
    }
    return { ...newState, controls };
  });
};


export const validateControls = (controls: IControl) => {
  let isValid = true;
  for (let controlIdentifier in controls) {
    isValid = controls[controlIdentifier].valid && isValid;
  }
  return isValid;
};

export const initForm = function(controls: IControl, data: any, disableFields?: boolean) {
  let validation = null;
  for (let key in data) {
    if (controls[key]) {
      validation = validateInput(controls, key, data[key], disableFields || false);
      controls = validation.controls;
    }
  }

  return {
    controls: validation ? validation.controls : controls,
    isValid: validation ? validation.isValid : false
  };
};

export const getFormData = function(controls: IControl): IFormData {
  const formData: IFormData = {};
  for (let key in controls) {
    let value = controls[key].value;
    if (controls[key].type === "date" && isMoment(value)) {
      value = value.format("DD.MM.YYYY");
    }
    formData[key] = value;
  }
  return formData;
};

export const defaultInputChangedHandler = (
  value: TFormElementValue,
  controlName: string,
  setControls: TSetControls
) => {
  setControls((prevControls) => {
    return validateInput(prevControls.controls, controlName, value, false);
  })
};

export const controlsToFormGroups = (
  controls: IControl,
  setControls: TSetControls
): JSX.Element[] => {
  const array: IFormControl[] = [];
  for (let key in controls) {
    array.push({ id: key, config: controls[key] });
  }

  return array.map(item => {
    return (
      <Input
        key={item.id}
        formControl={item}
        changed={(value: TFormElementValue, controlName: string) =>
          defaultInputChangedHandler(value, controlName, setControls)
        }
      />
    );
  });
};

export const dateToString = (date: Date): string => {
  return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
};
