import { IAttachment } from './IAttachment';

export enum EVisibleGroup {
  SUEK_INSTRUCTOR = "SUEK_INSTRUCTOR",
  ACADEMY_INSTRUCTOR = "ACADEMY_INSTRUCTOR",
  UNION_INSTRUCTOR = "UNION_INSTRUCTOR",
  LLK_INSTRUCTOR = "LLK_INSTRUCTOR"
}

export interface IMaterial {
  id: string;
  name: string;
  description: string;
  attachments: IAttachment[];
  visibleGroups: EVisibleGroup[];
  links: string[];
}