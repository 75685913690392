import axios from "../axiosCF"



export const refreshExpiry = async () => {
  try {
    await axios.post('/auth/refresh', { timestamp: new Date().getTime() });
    return true;
  } catch (err) {
    return false;
  }
}
