import { Fab } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import React, { ReactNode, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import axios from '../../../axios';
import CustomDialog from '../../../components/UI/CustomDialog/CustomDialog';
import withErrorHandler from '../../../hoc/withErrorHandler/withErrorHandler';
import { IMaterial } from '../../../interfaces/domain';
import { IAppState } from '../../../interfaces/state';
import * as actions from '../../../store/actions';

const classes = require("./MaterialsList.module.scss");

interface IStateProps {
  materials: IMaterial[];
  loading: boolean;
  isAdmin: boolean;
}

interface IDispatchProps {
  onFetchMaterials: () => void;
  onSetMaterial: (material: IMaterial) => void;
  onDeleteMaterial: (material: IMaterial) => void;
}

interface IProps extends IStateProps, IDispatchProps, RouteComponentProps {}

const MaterialsList: React.FC<IProps> = ({
  loading,
  materials,
  isAdmin,
  onFetchMaterials,
  history,
  onSetMaterial,
  onDeleteMaterial
}) => {
  const [deleteMaterial, setDeleteMaterial] = useState<IMaterial | null>(null);

  useEffect(() => {
    onFetchMaterials();
  }, [onFetchMaterials]);

  const addHandler = () => {
    history.push("/materials/add");
  };

  const editHandler = (item: IMaterial) => {
    onSetMaterial(item);
    history.push(`/materials/${item.id}`);
  };

  const deleteHandler = (material: IMaterial) => {
    setDeleteMaterial(material);
  };

  const deleteMaterialHandler = (material: IMaterial | null) => {
    if (material) {
      setDeleteMaterial(null);
      onDeleteMaterial(material);
    }
  };

  const createLink = (link: string, name: string) => {
    if(link.indexOf('/tools') === -1 && link.indexOf('http') === -1) {
      link = `https://${link}`;
    }
    return (
      <p key={link}>
        <a href={link} target="_blank" rel="noopener noreferrer">
          {name}
        </a>
      </p>
    )
  }

  let materialsElement:ReactNode = null;
  if (loading) {
    materialsElement = <LinearProgress color="secondary" />;
  } else if (materials && materials.length) {
    materialsElement = (
      <Paper elevation={4} className={classes.Paper}>
        <CustomDialog
          title="Poista materiaali"
          content="Haluatko varmasti poistaa materiaalin?"
          buttonText="Poista"
          onCancel={() => setDeleteMaterial(null)}
          onOk={() => deleteMaterialHandler(deleteMaterial)}
          open={!!deleteMaterial}
        />
        <Table className={classes.Table}>
          <TableHead>
            <TableRow>
              <TableCell>Nimi</TableCell>
              <TableCell>Kuvaus</TableCell>
              <TableCell>Liitteet</TableCell>
              {isAdmin ? <TableCell></TableCell> : null}
            </TableRow>
          </TableHead>
          <TableBody>
            {materials.map(item => (
              <TableRow key={item.id}>
                <TableCell>{item.name}</TableCell>
                <TableCell>
                  {item.description &&
                    item.description.split("\n").map((item, key) => (
                      <span key={key}>
                        {item}
                        <br />
                      </span>
                    ))}
                </TableCell>
                <TableCell>
                  {item.attachments &&
                    item.attachments.map((attachment, key) => createLink(attachment.link, attachment.name))}
                    {item.links && item.links.map((link) => createLink(link, link))}
                </TableCell>
                {isAdmin ? (
                  <TableCell>
                    <IconButton
                      aria-label="Muokkaa materiaalia"
                      onClick={() => editHandler(item)}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      aria-label="Poista materiaali"
                      onClick={() => deleteHandler(item)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                ) : null}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    );
  } else {
    materialsElement = (
      <Paper elevation={4} className={classes.Paper}>
        <Typography gutterBottom component="b">
          Ei materiaaleja.
        </Typography>
      </Paper>
    );

    if (isAdmin) {
      materialsElement = (
        <Paper elevation={4} className={classes.Paper}>
          <Typography gutterBottom component="b">
            Ei materiaaleja. Aloita lisäämällä.
          </Typography>
          <Button color="primary" onClick={addHandler}>
            Lisää uusi
          </Button>
        </Paper>
      );
    }
  }

  return (
    <React.Fragment>
      {materialsElement}
      {isAdmin ? (
        <Fab
          onClick={addHandler}
          color="primary"
          aria-label="add"
          className={classes.AddButton}
        >
          <AddIcon />
        </Fab>
      ) : null}
    </React.Fragment>
  );
};

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    materials: state.materials.materials,
    loading: state.materials.loading,
    isAdmin: state.auth.user ? state.auth.user.permissions.koulutusAdmin : false
  };
};

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
  return {
    onFetchMaterials: () => dispatch(actions.fetchMaterials()),
    onSetMaterial: material => dispatch(actions.setMaterial(material)),
    onDeleteMaterial: material => dispatch(actions.deleteMaterial(material))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withErrorHandler(MaterialsList, axios));
