import { Dispatch } from "redux";

import axios from "../../axiosCF";
import { IAcademyEducationAction } from "../../interfaces/action/IAcademyEducationAction";
import { IAcademyEducation } from "../../interfaces/domain/IAcademyEducation";
import { ActionTypes } from "./actionTypes";

export const saveAcademyEducationSuccess = (
  academyEducation: IAcademyEducation
): IAcademyEducationAction => {
  return {
    type: ActionTypes.SAVE_ACADEMY_EDUCATION_SUCCESS,
    academyEducation,
  };
};

export const saveAcademyEducationFail = (
  error: string
): IAcademyEducationAction => {
  return {
    type: ActionTypes.SAVE_ACADEMY_EDUCATION_FAIL,
    error,
  };
};

export const saveAcademyEducationStart = (): IAcademyEducationAction => {
  return {
    type: ActionTypes.SAVE_ACADEMY_EDUCATION_START,
  };
};

export const saveAcademyEducation = (formData: FormData) => {
  return async (dispatch: Dispatch): Promise<IAcademyEducation | null> => {
    dispatch(saveAcademyEducationStart());
    try {
      const response = await axios.post(
        `/api/academyeducations/add`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const academyEducation = response.data as IAcademyEducation;
      dispatch(saveAcademyEducationSuccess(academyEducation));
      return academyEducation;
    } catch (error) {
      dispatch(saveAcademyEducationFail(error as string));
      return null;
    }
  };
};

export const findAcademyEducationsSuccess = (
  academyEducations: IAcademyEducation[]
): IAcademyEducationAction => {
  return {
    type: ActionTypes.LIST_ACADEMY_EDUCATION_SUCCESS,
    academyEducations,
  };
};

export const findAcademyEducationsFail = (
  error: string
): IAcademyEducationAction => {
  return {
    type: ActionTypes.LIST_ACADEMY_EDUCATION_FAIL,
    error,
  };
};

export const findAcademyEducationsStart = (): IAcademyEducationAction => {
  return {
    type: ActionTypes.LIST_ACADEMY_EDUCATION_START,
  };
};

export const findAcademyEducations = () => {
  return async (dispatch: Dispatch): Promise<IAcademyEducation[] | null> => {
    dispatch(findAcademyEducationsStart());
    try {
      const response = await axios.post(
        `/api/academyeducations/find/created`,
        { }
      );
      const academyEducations = response.data as IAcademyEducation[];
      dispatch(findAcademyEducationsSuccess(academyEducations));
      return academyEducations;
    } catch (error) {
      dispatch(findAcademyEducationsFail(error as string));
      return null;
    }
  };
};
