import { useEffect } from "react";

export const useBlurBeforeUnload = () => {
  useEffect(() => {
    const beforeUnload = () => {
      try {
        (document.activeElement as HTMLElement).blur();
      } catch (e) {}
    };
    window.addEventListener("beforeunload", beforeUnload);
    return () => {
      window.removeEventListener("beforeunload", beforeUnload);
    };
  }, []);
};
