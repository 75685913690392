import React, { useContext } from "react";
import { EEducationExpenseFields } from "../../../containers/EducationExpenses/EducationExpense/EducationExpense";
import EducationExpenseContext from "../../../containers/EducationExpenses/EducationExpense/EducationExpenseContext";
import EducationExpenseSection from "../EducationExpenseSection/EducationExpenseSection";

interface IProps {
  disabled: boolean;
}

const EducationExpenseCostCenter: React.FC<IProps> = ({ disabled }) => {
  const { createInput } = useContext(EducationExpenseContext);
  return (
    <EducationExpenseSection title="Kustannuspaikka">
      <>
        {createInput(EEducationExpenseFields.costCenter, { disabled })}
      </>
    </EducationExpenseSection>
  );
}

export default EducationExpenseCostCenter;
