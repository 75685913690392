import React, { useCallback, useContext, useState } from 'react';
import { useDispatch } from 'react-redux';

import Dropzone from '../../../common-components/Dropzone/Dropzone';
import EducationExpenseContext from '../../../containers/EducationExpenses/EducationExpense/EducationExpenseContext';
import { IAttachment } from '../../../interfaces/domain';
import { TFormElementValue } from '../../../shared/utility';
import * as actions from '../../../store/actions';
import CustomDialog from '../../UI/CustomDialog/CustomDialog';
import EducationExpenseSection from '../EducationExpenseSection/EducationExpenseSection';
import EducationExpenseExtraFee from './EducationExpenseExtraFee/EducationExpenseExtraFee';

const EducationExpenseExtraFees: React.FC = () => {
  const [deleteExtraFee, setDeleteExtraFee] = useState<IAttachment | null>(
    null
  );

  const { educationExpense, isDisabled } = useContext(EducationExpenseContext);
  const educationExpenseId = educationExpense.id;
  const dispatch = useDispatch();

  const dropHandler = useCallback(
    (acceptedFiles: File[]) => {
      if (educationExpense && educationExpense.id) {
        dispatch(
          actions.educationExpenseUpload(educationExpense.id, acceptedFiles[0])
        );
      }
    },
    [dispatch, educationExpense]
  );

  const updateHandler = useCallback(
    (id: string, field: string, value: TFormElementValue) => {
      dispatch(
        actions.updateEducationExpenseFile({
          id,
          [field]: value,
          educationExpenseId,
        })
      );
      return Promise.resolve(true);
    },
    [dispatch, educationExpenseId]
  );

  const deleteHandler = useCallback(
    (attachment: IAttachment | null) => {
      if (attachment) {
        dispatch(
          actions.deleteEducationExpenseFile(attachment.id, educationExpenseId)
        );
        setDeleteExtraFee(null);
      }
    },
    [dispatch, educationExpenseId]
  );

  return (
    <>
      <CustomDialog
        title="Poista kulu"
        content="Haluatko varmasti poistaa kulun?"
        buttonText="Poista"
        onCancel={() => setDeleteExtraFee(null)}
        onOk={() => deleteHandler(deleteExtraFee)}
        open={!!deleteExtraFee}
      />
      <EducationExpenseSection title="Muut kulut">
        {educationExpense &&
          educationExpense.attachments &&
          educationExpense.attachments.map((attachment: IAttachment) => (
            <EducationExpenseExtraFee
              key={attachment.id}
              attachment={attachment}
              onDelete={(attachment) => setDeleteExtraFee(attachment)}
              onUpdate={updateHandler}
            />
          ))}
        <br />
        {!isDisabled && <Dropzone onDrop={dropHandler} />}
      </EducationExpenseSection>
    </>
  );
};

export default EducationExpenseExtraFees;
