import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import * as actions from '../../../store/actions/index';

interface IDispatchProps {
    onLogout: () => void;
}

interface IProps extends IDispatchProps {
    
}

const Logout:React.FC<IProps> = ({ onLogout }) => {

    useEffect(() => {
        onLogout();
    }, [onLogout])
    
    return <Redirect to="/" />
}

const mapDispatchToProps = (dispatch:any):IDispatchProps => {
    return {
        onLogout: () => dispatch(actions.logout())
    }
}

export default connect(null, mapDispatchToProps)(Logout);