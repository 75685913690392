import { updateObject } from "../../shared/utility";
import { ActionTypes } from "../actions/actionTypes";
import { IMaterialsAction } from "../../interfaces/action/IMaterialsAction";
import { IMaterialsState } from "../../interfaces/state/IMaterialsState";
import { IMaterial } from "../../interfaces/domain";

type ReducerSignature = (
  state: IMaterialsState,
  action: IMaterialsAction
) => IMaterialsState;

const initialState: IMaterialsState = {
  material: null,
  materials: [],
  loading: false,
  redirect: false
};

const saveMaterialStart: ReducerSignature = (state, action) => {
  return updateObject(state, { loading: true });
};

const saveMaterialSuccess: ReducerSignature = (state, action) => {
  let materials = [...state.materials];
  if (action.material) {
    materials = [action.material, ...materials];
  }
  return updateObject<IMaterialsState>(state, {
    loading: false,
    redirect: true,
    materials
  });
};

const saveMaterialFail: ReducerSignature = (state, action) => {
  return updateObject(state, { loading: false });
};

const updateMaterialStart: ReducerSignature = (state, action) => {
  return updateObject(state, { loading: true });
};

const updateMaterialSuccess: ReducerSignature = (state, action) => {
  const materials = [...state.materials];
  const material = action.material;
  if (material) {
    const index = materials.findIndex(item => item.id === material.id);
    materials[index] = material;
  }

  return updateObject(state, {
    loading: false,
    materials,
    redirect: true
  });
};

const updateMaterialFail: ReducerSignature = (state, action) => {
  return updateObject(state, { loading: false });
};

const fetchMaterialsStart: ReducerSignature = (state, action) => {
  return updateObject(state, { loading: true });
};

const fetchMaterialsSuccess: ReducerSignature = (state, action) => {
  let materials = action.materials;
  if (materials == null) {
    materials = [];
  }

  return updateObject(state, {
    materials,
    loading: false,
    redirect: false,
    material: null
  });
};

const fetchMaterialsFail: ReducerSignature = (state, action) => {
  return updateObject(state, {
    loading: false
  });
};

const getMaterialStart: ReducerSignature = (state, action) => {
  return updateObject(state, { loading: true });
};

const getMaterialSuccess: ReducerSignature = (state, action) => {
  return updateObject(state, {
    material: action.material,
    loading: false
  });
};

const getMaterialFail: ReducerSignature = (state, action) => {
  return updateObject(state, {
    loading: false
  });
};

const setMaterial: ReducerSignature = (state, action) => {
  return updateObject(state, {
    material: action.material
  });
};

const deleteMaterialStart: ReducerSignature = (state, action) => {
  return updateObject(state, { loading: true });
};

const deleteMaterialSuccess: ReducerSignature = (state, action) => {
  const material = action.material;
  let materials = null;
  if (material) {
    materials = state.materials.filter(item => item.id !== material.id);
  } else {
    materials = [...state.materials];
  }

  return updateObject(state, {
    materials,
    loading: false
  });
};

const deleteMaterialFail: ReducerSignature = (state, action) => {
  return updateObject(state, {
    loading: false
  });
};

const deleteAttachmentStart: ReducerSignature = (state, action) => {
  return updateObject(state, { loading: true });
};

const deleteAttachmentSuccess: ReducerSignature = (state, action) => {
  const materials = [...state.materials];
  let material = state.material as IMaterial;
  material.attachments = material.attachments.filter(
    item => item.id !== action.id
  );
  const index = materials.findIndex(item => item.id === material.id);
  materials[index] = material;

  return updateObject(state, {
    material: material,
    materials: materials,
    loading: false
  });
};

const deleteAttachmentFail: ReducerSignature = (state, action) => {
  return updateObject(state, {
    loading: false
  });
};

const reducer = (
  state = initialState,
  action: IMaterialsAction
): IMaterialsState => {
  switch (action.type) {
    case ActionTypes.FETCH_MATERIALS_START:
      return fetchMaterialsStart(state, action);
    case ActionTypes.FETCH_MATERIALS_SUCCESS:
      return fetchMaterialsSuccess(state, action);
    case ActionTypes.FETCH_MATERIALS_FAIL:
      return fetchMaterialsFail(state, action);
    case ActionTypes.SAVE_MATERIAL_START:
      return saveMaterialStart(state, action);
    case ActionTypes.SAVE_MATERIAL_SUCCESS:
      return saveMaterialSuccess(state, action);
    case ActionTypes.SAVE_MATERIAL_FAIL:
      return saveMaterialFail(state, action);
    case ActionTypes.UPDATE_MATERIAL_START:
      return updateMaterialStart(state, action);
    case ActionTypes.UPDATE_MATERIAL_SUCCESS:
      return updateMaterialSuccess(state, action);
    case ActionTypes.UPDATE_MATERIAL_FAIL:
      return updateMaterialFail(state, action);
    case ActionTypes.SET_MATERIAL:
      return setMaterial(state, action);
    case ActionTypes.GET_MATERIAL_START:
      return getMaterialStart(state, action);
    case ActionTypes.GET_MATERIAL_SUCCESS:
      return getMaterialSuccess(state, action);
    case ActionTypes.GET_MATERIAL_FAIL:
      return getMaterialFail(state, action);
    case ActionTypes.DELETE_MATERIAL_START:
      return deleteMaterialStart(state, action);
    case ActionTypes.DELETE_MATERIAL_SUCCESS:
      return deleteMaterialSuccess(state, action);
    case ActionTypes.DELETE_MATERIAL_FAIL:
      return deleteMaterialFail(state, action);
    case ActionTypes.DELETE_ATTACHMENT_START:
      return deleteAttachmentStart(state, action);
    case ActionTypes.DELETE_ATTACHMENT_SUCCESS:
      return deleteAttachmentSuccess(state, action);
    case ActionTypes.DELETE_ATTACHMENT_FAIL:
      return deleteAttachmentFail(state, action);
    default:
      return state;
  }
};

export default reducer;
