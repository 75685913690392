import * as dateFns from 'date-fns';
import Cookies from 'js-cookie';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { refreshExpiry } from '../services/authService';
import * as actions from '../store/actions';
import { IUser } from './../interfaces/domain/IUser';



export const useLoginExpirationHook = (user: IUser | null) => {
  const [isExpiring, setExpiring] = useState(false);
  const [trackMouse, setTrackMouse] = useState(false);
  const timer = useRef<NodeJS.Timeout | null>(null);
  

  const dispatch = useDispatch();


  const trackMouseHandler = useCallback(async () => {
    setTrackMouse(false);
    if(timer && timer.current) clearTimeout(timer.current);
    timer.current = setTimeout(async () => {
      await refreshExpiry();
    }, 100);
  }, []);


  useEffect(() => {
    if(user) {
      const interval = setInterval(() => {
        const expirationDateString = Cookies.get("EXPIRATION-DATE");
        if(expirationDateString) {
          const expirationDate = new Date(expirationDateString);
          const difference = dateFns.differenceInMinutes(expirationDate, new Date());
          if(difference < 10) {
            setTrackMouse(true);
          }
          setExpiring(difference < 5);
        } else {
          dispatch(actions.logoutSuccess());
          setTrackMouse(false);
          setExpiring(false);
          clearInterval(interval);
        }
      }, 10000);
  
      return () => {
        clearInterval(interval);
      }
    }
  }, [user, dispatch]);

  useEffect(() => {
    if(trackMouse) {
      document.addEventListener("mousemove", trackMouseHandler);
    } else {
      document.removeEventListener("mousemove", trackMouseHandler);
    }
  }, [trackMouse, trackMouseHandler])

  return { isExpiring };

}