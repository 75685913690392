import { IAcademyEducationState } from '../../interfaces/state';
import { updateObject } from '../../shared/utility';
import { ActionTypes } from '../actions/actionTypes';
import { IAcademyEducationAction } from '../../interfaces/action/IAcademyEducationAction';

type ReducerSignature = (
  state: IAcademyEducationState,
  action: IAcademyEducationAction
) => IAcademyEducationState;

const initialState: IAcademyEducationState = {
  academyEducation: null,
  academyEducations: null,
  loading: false,
  error: null
};

const saveAcademyEducationStart: ReducerSignature = (state, action) => {
  return updateObject(state, { loading: true });
};

const saveAcademyEducationSuccess: ReducerSignature = (state, action) => {
  return updateObject(state, {
    loading: false,
    academyEducation: action.academyEducation
  });
};

const saveAcademyEducationFail:ReducerSignature = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const listAcademyEducationsStart: ReducerSignature = (state, action) => {
  return updateObject(state, { loading: true });
};

const listAcademyEducationsSuccess: ReducerSignature = (state, action) => {
  return updateObject(state, {
    loading: false,
    academyEducations: action.academyEducations || null,
  });
};

const listAcademyEducationsFail:ReducerSignature = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const reducer = (state:IAcademyEducationState = initialState, action: IAcademyEducationAction): IAcademyEducationState => {
  switch (action.type) {
    case ActionTypes.SAVE_ACADEMY_EDUCATION_START:
      return saveAcademyEducationStart(state, action);
    case ActionTypes.SAVE_ACADEMY_EDUCATION_SUCCESS:
      return saveAcademyEducationSuccess(state, action);
    case ActionTypes.SAVE_ACADEMY_EDUCATION_FAIL:
      return saveAcademyEducationFail(state, action);
    case ActionTypes.LIST_ACADEMY_EDUCATION_START:
      return listAcademyEducationsStart(state, action);
    case ActionTypes.LIST_ACADEMY_EDUCATION_SUCCESS:
      return listAcademyEducationsSuccess(state, action);
    case ActionTypes.LIST_ACADEMY_EDUCATION_FAIL:
      return listAcademyEducationsFail(state, action);
    default:
      return state;
  }
};

export default reducer;
