import React from "react";
import { ETravelType } from "../../../data/education-expense";
import { IEducationExpense } from "../../../interfaces/domain";
import { IInputConfigOptional, IOption } from "../../../shared/utility";
import { EEducationExpenseFields } from "./EducationExpense";

const EducationExpenseContext = React.createContext<{
  educationExpense: IEducationExpense;
  createInput: (inputName: EEducationExpenseFields, config?: IInputConfigOptional) => void;
  educationOptions: IOption[];
  travelType: ETravelType;
  isDisabled: boolean;
  isRemoteSession: boolean;
  isAdmin: boolean;
}>({
  educationExpense: {} as IEducationExpense,
  createInput: () => {},
  educationOptions: [],
  travelType: ETravelType.OWN_CAR,
  isDisabled: false,
  isRemoteSession: false,
  isAdmin: false,
});

export default EducationExpenseContext;
