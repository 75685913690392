import { Dispatch } from "redux";
import axios from "../../axiosCF";
import { ISearchAction } from "../../interfaces/action/ISearchAction";
import { IOption } from "../../shared/utility";
import { ActionTypes } from "./actionTypes";

type TAction = ISearchAction;

const getSearchUsersStart = (): TAction => {
  return {
    type: ActionTypes.SEARCH_GET_SEARCH_USERS_START,
  };
};

const getSearchUsersSuccess = (users: IOption[]): TAction => {
  return {
    type: ActionTypes.SEARCH_GET_SEARCH_USERS_SUCCESS,
    users,
  };
};

const getSearchUsersFail = (error: string): TAction => {
  return {
    type: ActionTypes.SEARCH_GET_SEARCH_USERS_FAIL,
    error,
  };
};

export const getSearchUsers = (): Function => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(getSearchUsersStart());
    try {
      const response = await axios.get("/api/education-expenses/get-search-users");
      dispatch(getSearchUsersSuccess(response.data));
    } catch (error) {
      dispatch(getSearchUsersFail(error as string));
    }
  };
};
