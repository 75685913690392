import { Chip } from "@material-ui/core";
import React from "react";

import { EEducationExpenseStatus } from "../../../interfaces/domain/IEducationExpense";

import classes from './EducationExpenseStatus.module.scss';

interface IProps {
  status: EEducationExpenseStatus;
  isAdmin?: boolean;
}

const EducationExpenseStatus: React.FC<IProps> = ({ status, isAdmin }) => {
  let color: "primary" | "secondary" | "default" | undefined = "default";
  let label = "";
  let classNames = [];

  switch (status) {
    case EEducationExpenseStatus.NEW:
      label = "Uusi";
      color = "default";
      break;
    case EEducationExpenseStatus.SENT:
      label = "Lähetetty";
      color = "primary";
      if (isAdmin) {
        color = undefined;
        classNames.push(classes.Warning);
      }
      break;
    case EEducationExpenseStatus.CONFIRMED:
    case EEducationExpenseStatus.SEND_TO_NV_FAILED:
      if (isAdmin && status === EEducationExpenseStatus.SEND_TO_NV_FAILED) {
        label = "Virhe";
        color = "secondary";
      } else {
        label = "Hyväksytty";
        color = "primary";
        if (isAdmin) {
          color = undefined;
          classNames.push(classes.Warning);
        }
      }
      break;
    case EEducationExpenseStatus.SENT_TO_NV:
      label = "Valmis";
      color = "primary";
      break;
  }

  return <Chip color={color} label={label} className={classNames.join(' ')} />;
};

export default EducationExpenseStatus;
