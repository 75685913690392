import { Typography } from "@material-ui/core";
import React, { useContext } from "react";
import { EEducationExpenseFields } from "../../../containers/EducationExpenses/EducationExpense/EducationExpense";
import EducationExpenseContext from "../../../containers/EducationExpenses/EducationExpense/EducationExpenseContext";
import { ETravelType } from "../../../data/education-expense";
import EducationExpenseSection from "../EducationExpenseSection/EducationExpenseSection";

const EducationExpenseTravel: React.FC = () => {
  const { educationExpense, createInput, travelType, isRemoteSession, isAdmin } = useContext(EducationExpenseContext);

  const { reward, dailyAllowance, travelFee, travelFeePrice, educations } =
    educationExpense;
  return (
    <EducationExpenseSection title="Matkan tiedot">
      {/* Matka-aika datetimepickerit */}
      {isAdmin && createInput(EEducationExpenseFields.type)}
      {createInput(EEducationExpenseFields.travelStartDate, {
        disabled: educations.length > 0,
      })}
      {createInput(EEducationExpenseFields.travelEndDate, {
        disabled: educations.length > 0,
      })}
      {createInput(EEducationExpenseFields.infoSession)}
      {createInput(EEducationExpenseFields.remoteSession)}
      {reward && <Typography>Palkkio: {reward} €</Typography>}
      {!isRemoteSession && (
        <>
          {createInput(EEducationExpenseFields.distance)}
          {dailyAllowance && (
            <Typography>Päivärahat: {dailyAllowance} €</Typography>
          )}
          {/* Matkustustapa */}
          {createInput(EEducationExpenseFields.travelType)}
          {travelType === ETravelType.OTHER &&
            createInput(EEducationExpenseFields.travelTypeOther)}
          {/* Matkakulut / Matkan kokonaispituus km input */}
          {createInput(EEducationExpenseFields.travelKm)}
          {travelFee && (
            <Typography>
              x {travelFeePrice} €/km
              <br />
              Matkakulut yhteensä: {travelFee} €
            </Typography>
          )}
          {/* Matkustaja(t) */}
          {createInput(EEducationExpenseFields.passengers)}
          {/* Matkareitti */}
          {createInput(EEducationExpenseFields.travelRoute)}
        </>
      )}
    </EducationExpenseSection>
  );
};

export default EducationExpenseTravel;
