import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import React, { useContext, useEffect, useState } from 'react';
import EducationExpenseContext from '../../../../containers/EducationExpenses/EducationExpense/EducationExpenseContext';
import { useCreateInput } from '../../../../hooks/useCreateInput';
import { IAttachment } from '../../../../interfaces/domain';
import { IControls, initForm, TFormElementValue } from '../../../../shared/utility';
import { EInputType } from '../../../UI/Input/Input';
import classes from './EducationExpenseExtraFee.module.scss';

interface IProps {
  attachment: IAttachment;
  onDelete: (attachment: IAttachment) => void;
  onUpdate: (id: string, field: string, value: TFormElementValue) => void;
}

enum EFields {
  amount = "amount",
  description = "description",
}

const initControls: IControls = {
  controls: {
    [EFields.amount]: {
      type: EInputType.number,
      value: "",
      label: "Määrä (€)",
      validation: {
        required: true
      },
      valid: false
    },
    [EFields.description]: {
      type: EInputType.text,
      value: "",
      label: "Selite",
      multiline: true,
      validation: {
        required: true
      },
      valid: false
    },
  },
  isValid: false,
};

const EducationExpenseExtraFee: React.FC<IProps> = ({ attachment, onDelete, onUpdate }) => {
  const { isDisabled } = useContext(EducationExpenseContext);
  const [controls, setControls] = useState(initControls);

  useEffect(() => {
    setControls(initForm(initControls.controls, attachment));
  }, [attachment]);

  const createInput = useCreateInput(controls, setControls, {
    onBlur: (value, inputName) => onUpdate(attachment.id, inputName, value),
  });

  return (
    <>
      <div className={classes.Container}>
        <a href={attachment.link} target="_blank" rel="noopener noreferrer">
          <img src={attachment.link} alt={attachment.name} style={{ maxWidth: '5rem' }} />
        </a>
        {createInput(EFields.amount, { disabled: isDisabled })}
        {!isDisabled && (
          <IconButton aria-label="Poista liite" onClick={() => onDelete(attachment)}>
            <DeleteIcon />
          </IconButton>
        )}
      </div>
      {createInput(EFields.description, { disabled: isDisabled })}
    </>
  );
}

export default EducationExpenseExtraFee;
