import { Dispatch } from 'redux';

import axios from '../../axios';
import axiosCF from '../../axiosCF';
import { IAuthAction } from '../../interfaces/action';
import { IUser } from '../../interfaces/domain';
import { ActionTypes } from './actionTypes';




export const logoutStart = (): IAuthAction => {
  return {
    type: ActionTypes.LOGOUT_START
  };
};

export const logoutSuccess = (): IAuthAction => {
  return {
    type: ActionTypes.LOGOUT_SUCCESS
  };
};

export const logoutFail = (error: string): IAuthAction => {
  return {
    type: ActionTypes.LOGOUT_FAIL,
    error
  };
};

export const logout = () => {
  return async (dispatch: Dispatch) => {
    dispatch(logoutStart());
    try {
      await axiosCF.post(`/auth/logout`);
      dispatch(logoutSuccess());
      return true;
  } catch (error) {
      dispatch(logoutFail(error as string));
      return false;
  }        
  };
};

export const clearError = (): IAuthAction => {
  return {
    type: ActionTypes.CLEAR_ERROR
  };
};

export const getCurrentUserStart = (): IAuthAction => {
  return {
    type: ActionTypes.GET_CURRENT_USER_START
  };
};

export const getCurrentUserSuccess = (user: IUser): IAuthAction => {
  return {
    type: ActionTypes.GET_CURRENT_USER_SUCCESS,
    user
  };
};

export const getCurrentUserFail = (): IAuthAction => {
  return {
    type: ActionTypes.GET_CURRENT_USER_FAIL
  };
};

export const getCurrentUser = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(getCurrentUserStart());
      const res = await axios.get("/json/users/currentuser");
      const user = res.data.user as IUser;

      if (user && user.id) {
        dispatch(getCurrentUserSuccess(user));
      } else {
        dispatch(getCurrentUserFail());
      }
    } catch (error) {
      dispatch(getCurrentUserFail());
    }
  };
};
