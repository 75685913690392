const academies = [
  "FU/Solvallan Urheiluopisto",
  "Eerikkilän Urheiluopisto",
  "Kisakallion Urheiluopisto",
  "Kolmen kampuksen urheiluopisto, Helsinki",
  "Kuortaneen Urheiluopisto",
  "Liikuntakeskus Pajulahti",
  "Santasport Lapin Urheiluopisto",
  "Suomen Urheiluopisto",
  "Tanhuvaaran Urheiluopisto",
  "Urheiluopisto kisakeskus",
  "Varalan Urheiluopisto",
  "Vuokatin Urheiluopisto"
];

export const academyOptions = academies.map(academy => {
  return {
    value: academy,
    text: academy
  };
});

const targetGroups = [
  "Opiston henkilökunta",
  "Yläkoululeiritys",
  "Terveydenhuoltohenkilöstö",
  "AMK (liikunnanohjaaja)",
  "LPT oppisopimus (liikuntaneuvoja)",
  "LAT / VAT (valmennuksen osaamisala)",
  "LAT (liikunnan ammattitutkinto muut osaamisalat)",
  "VEAT (valmennuksen erikoisammattitutkinto)",
  "LOK (liikunnanohjauksen peruskurssi)",
  "LPT (liikunnanohjauksen perustutkinto, liikuntaneuvoja)",
  "PT (personal trainer)",
  "Urheilijasta valmentajaksi koulutukset (URVA) yhteistyössä Suomen Valmentajat ry:n kanssa",
  "Urheiluakatemian valmentajat",
  "Lukiolaiset"
];

export const targetGroupOptions = targetGroups.map(targetGroup => {
  return {
    value: targetGroup,
    text: targetGroup
  };
});

const educationContents = [
  "Antidopingasiat",
  "Kilpailumanipulaatio",
  "Katsomoturvallisuus",
  "Häirintä urheilussa"
];

export const educationContentOptions = educationContents.map(content => {
  return {
    value: content,
    text: content
  };
});
