import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import React, { ReactElement } from 'react';
import { NavLink } from 'react-router-dom';

interface IProps {
  title: string;
  link: string;
  onClick?: () => void;
  exact?: boolean;
  children: ReactElement;
}

const NavigationItem: React.FC<IProps> = ({
  children,
  title,
  link,
  exact,
  onClick
}) => (
  <ListItem
    button
    component={NavLink}
    onClick={onClick}
    to={link}
    exact={exact}
  >
    <ListItemIcon>{children}</ListItemIcon>
    <ListItemText primary={title} />
  </ListItem>
);

export default NavigationItem;
