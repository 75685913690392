const sportMedicineEducationContents = [
  "Antidopingasiat",
  "Kilpailumanipulaatio"
];

export const sportMedicineEducationContentOptions = sportMedicineEducationContents.map(content => {
  return {
    value: content,
    text: content
  };
});


const sportMedicineEducationTargetGroups = [
  "Urheilijat",
  "Valmentajat",
  "Vanhemmat",
  "Lääkärit",
  "Muu terveydenhuoltohenkilöstö",
];

export const sportMedicineEducationTargetGroupOptions = sportMedicineEducationTargetGroups.map(content => {
  return {
    value: content,
    text: content
  };
});

const sportMedicineCenters = [
  "Huippu-urheilun instituutti KIHU",
  "Paavo Nurmi -keskus (PNK)",
  "Tampereen Urheilulääkäriasema (UKK)",
  "Oulun Diakonissalaitos (ODL)",
  "Helsingin Urheilulääkäriasema (HULA)",
  "Kuopion liikuntalääketieteen tutkimuslaitos (KULTU)",
];

export const sportMedicineCenterOptions = sportMedicineCenters.map(content => {
  return {
    value: content,
    text: content
  };
});