import { ISuekEducation } from './../../interfaces/domain/ISuekEducation';
import { AxiosError } from "axios";
import { Dispatch } from "redux";
import axios, { getErrorMessage } from "../../axiosCF";
import { IEducationExpenseAction } from "../../interfaces/action/IEducationExpenseAction";
import { IAttachment } from "../../interfaces/domain";
import { IFormData } from "../../shared/utility";
import { IEducationExpense } from "./../../interfaces/domain/IEducationExpense";
import { ActionTypes } from "./actionTypes";

type TAction = IEducationExpenseAction;

const createEducationExpenseStart = (): TAction => {
  return {
    type: ActionTypes.EDUCATION_EXPENSES_CREATE_START,
  };
};

const createEducationExpenseSuccess = (id: string): TAction => {
  return {
    type: ActionTypes.EDUCATION_EXPENSES_CREATE_SUCCESS,
    id,
  };
};

const createEducationExpenseFail = (error: string): TAction => {
  return {
    type: ActionTypes.EDUCATION_EXPENSES_CREATE_FAIL,
    error,
  };
};

export const createEducationExpense = (): Function => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(createEducationExpenseStart());
    try {
      const response = await axios.post("/api/education-expenses/create");
      dispatch(createEducationExpenseSuccess(response.data));
    } catch (error) {
      dispatch(createEducationExpenseFail(getErrorMessage(error as AxiosError)));
    }
  };
};

const findEducationExpensesStart = (): TAction => {
  return {
    type: ActionTypes.EDUCATION_EXPENSES_FIND_START,
  };
};

const findEducationExpensesSuccess = (
  educationExpenses: IEducationExpense[],
  total: number,
): TAction => {
  return {
    type: ActionTypes.EDUCATION_EXPENSES_FIND_SUCCESS,
    educationExpenses,
    total,
  };
};

const findEducationExpensesFail = (error: string): TAction => {
  return {
    type: ActionTypes.EDUCATION_EXPENSES_FIND_FAIL,
    error,
  };
};


export const findEducationExpenses = (formData: IFormData): Function => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(findEducationExpensesStart());
    try {
      const response = await axios.post("/api/education-expenses/find", formData);
      dispatch(findEducationExpensesSuccess(response.data.items, response.data.total));
    } catch (error) {
      dispatch(findEducationExpensesFail(getErrorMessage(error as AxiosError)));
    }
  };
};


const getAddableEducationsStart = (): TAction => {
  return {
    type: ActionTypes.EDUCATION_EXPENSES_GET_ADDABLE_EDUCATIONS_START,
  };
};

const getAddableEducationsSuccess = (
  addableEducations: ISuekEducation[]
): TAction => {
  return {
    type: ActionTypes.EDUCATION_EXPENSES_GET_ADDABLE_EDUCATIONS_SUCCESS,
    addableEducations,
  };
};

const getAddableEducationsFail = (error: string): TAction => {
  return {
    type: ActionTypes.EDUCATION_EXPENSES_GET_ADDABLE_EDUCATIONS_FAIL,
    error,
  };
};


export const getAddableEducations = (id: string, startDate: string, endDate: string): Function => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(getAddableEducationsStart());
    try {
      const response = await axios.get(`/api/education-expenses/addable-educations?id=${id}&startDate=${startDate}&endDate=${endDate}`);
      dispatch(getAddableEducationsSuccess(response.data));
    } catch (error) {
      dispatch(getAddableEducationsFail(getErrorMessage(error as AxiosError)));
    }
  };
};

const getEducationExpenseStart = (): TAction => {
  return {
    type: ActionTypes.EDUCATION_EXPENSES_GET_START,
  };
};

const getEducationExpenseSuccess = (
  educationExpense: IEducationExpense
): TAction => {
  return {
    type: ActionTypes.EDUCATION_EXPENSES_GET_SUCCESS,
    educationExpense,
  };
};

const getEducationExpenseFail = (error: string): TAction => {
  return {
    type: ActionTypes.EDUCATION_EXPENSES_GET_FAIL,
    error,
  };
};

export const getEducationExpense = (id: string): Function => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(getEducationExpenseStart());
    try {
      const response = await axios.get(`/api/education-expenses/get?id=${id}`);
      dispatch(getEducationExpenseSuccess(response.data));
    } catch (error) {
      dispatch(getEducationExpenseFail(getErrorMessage(error as AxiosError)));
    }
  };
};

const updateEducationExpenseStart = (): TAction => {
  return {
    type: ActionTypes.EDUCATION_EXPENSES_UPDATE_START,
  };
};

const updateEducationExpenseSuccess = (
  educationExpense: IEducationExpense
): TAction => {
  return {
    type: ActionTypes.EDUCATION_EXPENSES_UPDATE_SUCCESS,
    educationExpense,
  };
};

const updateEducationExpenseFail = (error: string): TAction => {
  return {
    type: ActionTypes.EDUCATION_EXPENSES_UPDATE_FAIL,
    error,
  };
};

export const updateEducationExpense = (formData: IFormData) => {
  return async (dispatch: Dispatch): Promise<IEducationExpense | null> => {
    dispatch(updateEducationExpenseStart());
    try {
      const response = await axios.put(
        `/api/education-expenses/update`,
        formData
      );
      const educationExpense = response.data as IEducationExpense;
      dispatch(updateEducationExpenseSuccess(educationExpense));
      return educationExpense;
    } catch (error) {
      dispatch(updateEducationExpenseFail(getErrorMessage(error as AxiosError)));
      return null;
    }
  };
};

const deleteEducationExpenseStart = (): TAction => {
  return {
    type: ActionTypes.EDUCATION_EXPENSES_DELETE_START,
  };
};

const deleteEducationExpenseSuccess = (
  educationExpense: IEducationExpense
): TAction => {
  return {
    type: ActionTypes.EDUCATION_EXPENSES_DELETE_SUCCESS,
    educationExpense,
  };
};

const deleteEducationExpenseFail = (error: string): TAction => {
  return {
    type: ActionTypes.EDUCATION_EXPENSES_DELETE_FAIL,
    error,
  };
};

export const deleteEducationExpense = (
  educationExpense: IEducationExpense
): Function => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(deleteEducationExpenseStart());
    try {
      await axios.delete(
        `/api/education-expenses/delete?id=${educationExpense.id}`
      );
      dispatch(deleteEducationExpenseSuccess(educationExpense));
    } catch (error) {
      dispatch(deleteEducationExpenseFail(getErrorMessage(error as AxiosError)));
    }
  };
};

const uploadStart = (): TAction => {
  return {
    type: ActionTypes.EDUCATION_EXPENSE_FILES_UPLOAD_START,
  };
};

const uploadSuccess = (attachments: IAttachment[]): TAction => {
  return {
    type: ActionTypes.EDUCATION_EXPENSE_FILES_UPLOAD_SUCCESS,
    attachments,
  };
};

const uploadFail = (error: string): TAction => {
  return {
    type: ActionTypes.EDUCATION_EXPENSE_FILES_UPLOAD_FAIL,
    error,
  };
};

const uploadProgress = (progress: number): TAction => {
  return {
    type: ActionTypes.EDUCATION_EXPENSE_FILES_UPLOAD_PROGRESS,
    progress,
  };
};

export const educationExpenseUpload = (id: string, file: File) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(uploadStart());
      const formData = new FormData();
      formData.append("file", file);
      formData.append("id", id);
      const resp = await axios.post<IAttachment[]>(
        "/api/education-expenses/files/upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progress) => {
            dispatch(
              uploadProgress(
                Math.round((progress.loaded / progress.total) * 100)
              )
            );
          },
        }
      );
      dispatch(uploadSuccess(resp.data));
    } catch (error) {
      dispatch(uploadFail(getErrorMessage(error as AxiosError)));
    }
  };
};

const deleteEducationExpenseFileStart = (): TAction => {
  return {
    type: ActionTypes.EDUCATION_EXPENSES_FILES_DELETE_START,
  };
};

const deleteEducationExpenseFileSuccess = (educationExpense: IEducationExpense): TAction => {
  return {
    type: ActionTypes.EDUCATION_EXPENSES_FILES_DELETE_SUCCESS,
    educationExpense
  };
};

const deleteEducationExpenseFileFail = (error: string): TAction => {
  return {
    type: ActionTypes.EDUCATION_EXPENSES_FILES_DELETE_FAIL,
    error,
  };
};

export const deleteEducationExpenseFile = (
  id: string,
  educationExpenseId: string
): Function => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(deleteEducationExpenseFileStart());
    try {
      const resp = await axios.delete(
        `/api/education-expenses/files/delete?id=${id}&educationExpenseId=${educationExpenseId}`
      );
      dispatch(deleteEducationExpenseFileSuccess(resp.data));
    } catch (error) {
      dispatch(deleteEducationExpenseFileFail(getErrorMessage(error as AxiosError)));
    }
  };
};

const updateEducationExpenseFileStart = (): TAction => {
  return {
    type: ActionTypes.EDUCATION_EXPENSES_FILES_UPDATE_START,
  };
};

const updateEducationExpenseFileSuccess = (
  educationExpense: IEducationExpense
): TAction => {
  return {
    type: ActionTypes.EDUCATION_EXPENSES_FILES_UPDATE_SUCCESS,
    educationExpense,
  };
};

const updateEducationExpenseFileFail = (error: string): TAction => {
  return {
    type: ActionTypes.EDUCATION_EXPENSES_FILES_UPDATE_FAIL,
    error,
  };
};

export const updateEducationExpenseFile = (formData: IFormData) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(updateEducationExpenseFileStart());
    try {
      const response = await axios.put<IEducationExpense>(
        `/api/education-expenses/files/update`,
        formData
      );
      dispatch(updateEducationExpenseFileSuccess(response.data));
    } catch (error) {
      dispatch(updateEducationExpenseFileFail(getErrorMessage(error as AxiosError)));
    }
  };
};

const sendStart = (): TAction => {
  return {
    type: ActionTypes.EDUCATION_EXPENSES_SEND_START,
  };
};

const sendSuccess = (educationExpense: IEducationExpense): TAction => {
  return {
    type: ActionTypes.EDUCATION_EXPENSES_SEND_SUCCESS,
    educationExpense,
  };
};

const sendFail = (error: string): TAction => {
  return {
    type: ActionTypes.EDUCATION_EXPENSES_SEND_FAIL,
    error,
  };
};

export const sendEducationExpense = (id: string) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(sendStart());
    try {
      const response = await axios.post<IEducationExpense>(
        `/api/education-expenses/send?id=${id}`
      );
      dispatch(sendSuccess(response.data));
    } catch (error) {
      dispatch(sendFail(getErrorMessage(error as AxiosError)));
    }
  };
};



const sendToNetvisorStart = (): TAction => {
  return {
    type: ActionTypes.EDUCATION_EXPENSES_SEND_TO_NETVISOR_START,
  };
};

const sendToNetvisorSuccess = (educationExpenses: IEducationExpense[]): TAction => {
  return {
    type: ActionTypes.EDUCATION_EXPENSES_SEND_TO_NETVISOR_SUCCESS,
    educationExpenses,
  };
};

const sendToNetvisorFail = (error: string): TAction => {
  return {
    type: ActionTypes.EDUCATION_EXPENSES_SEND_TO_NETVISOR_FAIL,
    error,
  };
};

export const sendToNetvisor = (ids: string[]) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(sendToNetvisorStart());
    try {
      const response = await axios.post<IEducationExpense[]>(
        `/api/education-expenses/netvisor`,
        { ids }
      );
      dispatch(sendToNetvisorSuccess(response.data));
    } catch (error) {
      dispatch(sendToNetvisorFail(getErrorMessage(error as AxiosError)));
    }
  };
};

export const sendToNetvisorClear = (): TAction => {
  return {
    type: ActionTypes.EDUCATION_EXPENSES_SEND_TO_NETVISOR_CLEAR,
  };
};