import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import React, { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";

import axios from "../../../axios";
import CustomDialog from "../../../components/UI/CustomDialog/CustomDialog";
import FileItem from "../../../components/UI/FileItem/FileItem";
import withErrorHandler from "../../../hoc/withErrorHandler/withErrorHandler";
import {
  EVisibleGroup,
  IAttachment,
  IMaterial,
} from "../../../interfaces/domain";
import { IAppState } from "../../../interfaces/state";
import {
  createAttachment,
  IAttachmentFile,
  uploadFile,
} from "../../../services/attachmentService";
import { updateObject } from "../../../shared/utility";
import * as actions from "../../../store/actions";

const classes = require("./MaterialsEdit.module.scss");

interface IStateProps {
  loading: boolean;
  material: IMaterial | null;
  redirect: boolean;
}

interface IDispatchProps {
  onSaveMaterial: (material: Object) => Promise<void>;
  onUpdateMaterial: (id: string, material: Object) => Promise<void>;
  onGetMaterial: (id: string) => void;
  onDeleteAttachment: (id: string) => void;
}

interface IMatch {
  id: string;
}

interface IProps
  extends IStateProps,
    IDispatchProps,
    RouteComponentProps<IMatch> {}

interface IMaterialFormControl {
  elementType: string;
  elementConfig: {
    label: string;
    multiline?: boolean;
  };
  value: string | string[];
}

interface IMaterialForm {
  [key: string]: IMaterialFormControl;
}

const MaterialsEdit: React.FC<IProps> = ({
  material,
  loading,
  redirect,
  match,
  history,
  onSaveMaterial,
  onUpdateMaterial,
  onGetMaterial,
  onDeleteAttachment,
}) => {
  const [materialForm, setMaterialForm] = useState<IMaterialForm>({
    name: {
      elementType: "input",
      elementConfig: {
        label: "Nimi",
      },
      value: "",
    },
    description: {
      elementType: "input",
      elementConfig: {
        label: "Kuvaus",
        multiline: true,
      },
      value: "",
    },
  });

  const [isUploading, setIsUploading] = useState(false);
  const [links, setLinks] = useState<string[]>([]);

  const [visibleGroups, setVisibleGroups] = useState<EVisibleGroup[]>([
    EVisibleGroup.ACADEMY_INSTRUCTOR,
    EVisibleGroup.SUEK_INSTRUCTOR,
    EVisibleGroup.UNION_INSTRUCTOR,
    EVisibleGroup.LLK_INSTRUCTOR,
  ]);
  const [notifyUsers, setNotifyUsers] = useState<boolean>(false);

  const [files, setFiles] = useState<IAttachmentFile[]>([]);
  const [attachments, setAttachments] = useState<IAttachment[] | null>(null);
  const [deleteAttachment, setDeleteAttachment] = useState<IAttachment | null>(
    null
  );

  const { id } = match.params;

  useEffect(() => {
    if (id && id !== "add") {
      onGetMaterial(id);
    }
  }, [id, onGetMaterial]);

  useEffect(() => {
    if (material) {
      setMaterialForm({
        ...materialForm,
        name: {
          ...materialForm.name,
          value: material.name,
        },
        description: {
          ...materialForm.description,
          value: material.description,
        },
      });
      setAttachments(material.attachments);
      setVisibleGroups(material.visibleGroups || []);
      setLinks(material.links || []);
    }
    // eslint-disable-next-line
  }, [material]);

  const submitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setIsUploading(true);

    const data: {
      [key: string]: string | EVisibleGroup[] | string[] | IAttachment[];
    } = {};

    for (let formElementIdentifier in materialForm) {
      const value = materialForm[formElementIdentifier].value as string;
      data[formElementIdentifier] = value;
    }

    data.visibleGroups = [...visibleGroups];
    data.links = [...links];
    data.notifyUsers = String(notifyUsers);

    if (files && files.length > 0) {
      const newFiles = files.map((file, index) => {
        file.id = index;
        return file;
      });

      const createAttachments = await Promise.all(
        newFiles.map(async (file) => await createAttachment(file))
      );

      const attachments = await Promise.all(
        createAttachments.map(async (createAttachment) => {
          return await uploadFile(createAttachment, (progress, id) => {
            setFiles((files) =>
              files.map((file) => {
                if (file.id !== id) return file;
                file.progress = progress;
                return file;
              })
            );
          });
        })
      );

      data.attachments = attachments;
    }

    setIsUploading(false);

    if (id != null) {
      await onUpdateMaterial(id, data);
    } else {
      await onSaveMaterial(data);
    }
    history.push("/materials");
  };

  const cancelHandler = () => {
    history.push("/materials");
  };

  const onDropHandler = async (addedFiles: File[]) => {
    const newFiles = [...files, ...addedFiles];
    setFiles(newFiles);
  };

  const inputChangedHandler = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    inputIdentifier: string
  ) => {
    const updatedFormElement = updateObject(materialForm[inputIdentifier], {
      value: event.target.value,
    });

    const updatedMaterialForm = updateObject(materialForm, {
      [inputIdentifier]: updatedFormElement,
    });

    setMaterialForm(updatedMaterialForm);
  };

  // Show confirm or remove files that are not in server
  const deleteFileHandler = (file: File, index: number) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);
  };

  const visibleGroupHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value as EVisibleGroup;
    if (event.target.checked) {
      setVisibleGroups((prevState) => {
        return [...prevState, value];
      });
    } else {
      setVisibleGroups((prevState) => {
        return prevState.filter((item) => item !== value);
      });
    }
  };

  // Delete file from server
  const deleteAttachmentHandler = (attachment: IAttachment | null) => {
    if (!attachment) return;
    const newAttachments =
      (attachments &&
        attachments.filter((item) => item.id !== attachment.id)) ||
      [];
    setAttachments(newAttachments);
    setDeleteAttachment(null);
    onDeleteAttachment(attachment.id);
  };

  const deleteLinkHandler = (index: number) => {
    const newLinks = [...links];
    newLinks.splice(index, 1);
    setLinks(newLinks);
  };

  const formElementsArray = [];
  for (let key in materialForm) {
    formElementsArray.push({
      id: key,
      config: materialForm[key],
    });
  }

  let content = null;

  if (loading) {
    content = <LinearProgress color="secondary" />;
  } else {
    content = (
      <Paper elevation={4}>
        <CustomDialog
          title="Poista tiedosto"
          content="Haluatko varmasti poistaa tiedoston?"
          buttonText="Poista"
          onCancel={() => setDeleteAttachment(null)}
          onOk={() => deleteAttachmentHandler(deleteAttachment)}
          open={!!deleteAttachment}
        />
        <form className={classes.MaterialsEdit} onSubmit={submitHandler}>
          {!isUploading && (
            <>
              {formElementsArray.map((formElement) => (
                <TextField
                  key={formElement.id}
                  label={formElement.config.elementConfig.label}
                  margin="normal"
                  className={classes.TextField}
                  value={formElement.config.value}
                  multiline={formElement.config.elementConfig.multiline}
                  onChange={(event) =>
                    inputChangedHandler(event, formElement.id)
                  }
                />
              ))}

              <FormControl component="fieldset">
                <FormLabel component="legend">Ryhmät</FormLabel>
                <FormGroup row>
                  <FormControlLabel
                    labelPlacement="end"
                    label="SUEK kouluttajat"
                    control={
                      <Checkbox
                        value={EVisibleGroup.SUEK_INSTRUCTOR}
                        onChange={visibleGroupHandler}
                        checked={visibleGroups.includes(
                          EVisibleGroup.SUEK_INSTRUCTOR
                        )}
                      />
                    }
                  />
                </FormGroup>
                <FormGroup row>
                  <FormControlLabel
                    labelPlacement="end"
                    label="Opistokouluttajat"
                    control={
                      <Checkbox
                        value={EVisibleGroup.ACADEMY_INSTRUCTOR}
                        onChange={visibleGroupHandler}
                        checked={visibleGroups.includes(
                          EVisibleGroup.ACADEMY_INSTRUCTOR
                        )}
                      />
                    }
                  />
                </FormGroup>
                <FormGroup row>
                  <FormControlLabel
                    labelPlacement="end"
                    label="Liittokouluttajat"
                    control={
                      <Checkbox
                        value={EVisibleGroup.UNION_INSTRUCTOR}
                        onChange={visibleGroupHandler}
                        checked={visibleGroups.includes(
                          EVisibleGroup.UNION_INSTRUCTOR
                        )}
                      />
                    }
                  />
                </FormGroup>
                <FormGroup row>
                  <FormControlLabel
                    labelPlacement="end"
                    label="LLK-kouluttajat"
                    control={
                      <Checkbox
                        value={EVisibleGroup.LLK_INSTRUCTOR}
                        onChange={visibleGroupHandler}
                        checked={visibleGroups.includes(
                          EVisibleGroup.LLK_INSTRUCTOR
                        )}
                      />
                    }
                  />
                </FormGroup>
              </FormControl>

              <div>
                <p className={classes.LinksHeader}>
                  Linkit{" "}
                  <AddIcon
                    className={classes.AddIcon}
                    onClick={() => {
                      setLinks((linksState) => {
                        return [...linksState, ""];
                      });
                    }}
                  />{" "}
                </p>
                {links.map((link, i) => {
                  return (
                    <div key={i} className={classes.LinkItem}>
                      <TextField
                        label={`Linkki ${i + 1}`}
                        margin="normal"
                        className={classes.TextField}
                        value={link}
                        multiline={false}
                        onChange={(event) => {
                          const newLinks = [...links];
                          newLinks[i] = event.target.value;
                          setLinks(newLinks);
                        }}
                      />
                      <DeleteIcon
                        className={classes.DeleteIcon}
                        onClick={() => deleteLinkHandler(i)}
                      />
                    </div>
                  );
                })}
              </div>

              <Dropzone onDrop={onDropHandler}>
                {({ getRootProps, getInputProps, isDragActive }) => {
                  return (
                    <div {...getRootProps()} className={classes.Dropzone}>
                      <input {...getInputProps()} />
                      <p>Paina tästä tai pudota tiedostoja.</p>
                    </div>
                  );
                }}
              </Dropzone>
            </>
          )}

          <Grid container spacing={2}>
            {files &&
              files.map((file, key) => (
                <FileItem
                  key={key}
                  name={file.name}
                  isUploading={isUploading}
                  progress={file.progress}
                  onDelete={() => deleteFileHandler(file, key)}
                />
              ))}
            {attachments &&
              attachments.map((attachment) => (
                <FileItem
                  key={attachment.id}
                  name={attachment.name}
                  isUploading={isUploading}
                  onDownload={() => window.open(attachment.link)}
                  onDelete={() => setDeleteAttachment(attachment)}
                />
              ))}
          </Grid>

          {!isUploading && (
            <FormControl>
              <FormGroup row>
                <FormControlLabel
                  labelPlacement="end"
                  label="Lähetä ilmoitus"
                  control={
                    <Checkbox
                      value={true}
                      onChange={(event) => setNotifyUsers(event.target.checked)}
                      checked={notifyUsers}
                    />
                  }
                />
              </FormGroup>
            </FormControl>
          )}

          <br />

          <Button
            variant="text"
            color="primary"
            className={classes.Button}
            type="submit"
            disabled={isUploading}
          >
            Tallenna
          </Button>

          <Button
            variant="text"
            className={classes.Button}
            onClick={cancelHandler}
            disabled={isUploading}
          >
            Peruuta
          </Button>
        </form>
      </Paper>
    );
  }

  return content;
};

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    loading: state.materials.loading,
    material: state.materials.material,
    redirect: state.materials.redirect,
  };
};

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
  return {
    onSaveMaterial: (material) => dispatch(actions.saveMaterial(material)),
    onUpdateMaterial: (id, material) =>
      dispatch(actions.updateMaterial(id, material)),
    onGetMaterial: (id) => dispatch(actions.getMaterial(id)),
    onDeleteAttachment: (id) => dispatch(actions.deleteAttachment(id)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withErrorHandler(MaterialsEdit, axios));
