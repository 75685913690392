import {
  Chip,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import axios from '../../axiosCF';
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler';
import { ESuekEducationStatus, ISuekEducation } from '../../interfaces/domain';
import { IAppState } from '../../interfaces/state';
import * as actions from '../../store/actions';

const classes = require("./SUEKEducations.module.scss");

interface IStateProps {
  loading: boolean;
  suekEducations: ISuekEducation[];
}

interface IDispatchProps {
  onFindSuekEducations: () => void;
}

interface IProps extends RouteComponentProps, IStateProps, IDispatchProps {}

const SUEKEducations: React.FC<IProps> = ({
  history,
  onFindSuekEducations,
  loading,
  suekEducations
}) => {
  useEffect(() => {
    onFindSuekEducations();
  }, [onFindSuekEducations]);

  if (loading) {
    return <LinearProgress color="primary" />;
  }

  const rowClickHandler = (id: number) => {
    history.push(`/educations/${id}`);
  };

  const renderTableHead = () => {
    const headers = ["Tila", "Nimi", "Paikka", "Aloitus pvm", "Teema", "Laji"];
    return (
      <TableHead>
        <TableRow>
          {headers.map(headerText => (
            <TableCell key={headerText}>{headerText}</TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  };

  const renderEducations = () => {
    if (!suekEducations || !suekEducations.length) {
      return (
        <Typography gutterBottom component="b">
          Ei koulutuksia.
        </Typography>
      );
    }
    return (
      <Table className={classes.Table}>
        {renderTableHead()}
        <TableBody>
          {suekEducations.map(education => {
            let status = null;
            switch (education.status) {
              case ESuekEducationStatus.DONE:
                status = <Chip color="primary" label="Valmis"/>;
                break;
              case ESuekEducationStatus.OPEN:
                status = <Chip color="secondary" label="Avoinna" />;
                break;
              default:
            }

            return (
              <TableRow
                className={classes.TableRow}
                key={education.id}
                hover
                onClick={() => rowClickHandler(education.id)}
              >
                <TableCell>{status}</TableCell>
                <TableCell>{education.name}</TableCell>
                <TableCell>{education.place}</TableCell>
                <TableCell>{education.startDate}</TableCell>
                <TableCell>{education.theme.join(", ")}</TableCell>
                <TableCell>{education.sport}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    );
  };

  return (
    <Paper elevation={4} className={classes.Paper}>
      {renderEducations()}
    </Paper>
  );
};

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    loading: state.suekEducations.loading,
    suekEducations: state.suekEducations.suekEducations
  };
};

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
  return {
    onFindSuekEducations: () => dispatch(actions.findSuekEducations())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withErrorHandler(SUEKEducations, axios));
