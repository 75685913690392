import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import SuekLogo from "../../assets/images/suek-logo-M.png";
import { loginUiUrl } from "../../config";
import { IUser } from "../../interfaces/domain";
import { IAppState } from "../../interfaces/state";

const classes = require("./Home.module.scss");

interface IStateProps {
  user: IUser | null;
  isAuthenticated: boolean;
}

interface IProps extends IStateProps {}

const Home: React.FC<IProps> = ({ user, isAuthenticated }) => {
  let content = (
    <React.Fragment>
      <Typography variant="h3" paragraph component="h1">
        KOULUTUS
      </Typography>
      <Typography variant="h5" paragraph>
        Aloita kirjautumalla sisään.
      </Typography>
      <Button variant="outlined" color="primary" onClick={() => window.location.href = loginUiUrl } >
        Kirjaudu sisään
      </Button>
    </React.Fragment>
  );

  if (user !== null) {
    content = (
      <React.Fragment>
        <Typography variant="h3" gutterBottom>
          TERVETULOA
        </Typography>
        <Typography variant="h5" gutterBottom>
          {user ? user.name : ""}
        </Typography>
        <Button color="primary" component={Link} to="/materials">
          Materiaalit
        </Button>
        {user.permissions.academyInstructor && (
          <Button
            color="primary"
            component={Link}
            to="/opistokoulutukset/luo-raportti"
          >
            Uusi opistokoulutus
          </Button>
        )}
        {user.permissions.unionInstructor && (
          <Button
            color="primary"
            component={Link}
            to="/liittokoulutukset/luo-raportti"
          >
            Uusi liittokoulutus
          </Button>
        )}
        {user.permissions.llkInstructor && (
          <Button
            color="primary"
            component={Link}
            to="/llk-koulutukset/luo-raportti"
          >
            Uusi LLK-koulutus
          </Button>
        )}
        {user.permissions.suekInstructor && (
          <Button
            color="primary"
            component={Link}
            to="/educations"
          >
            Koulutukset
          </Button>
        )}
        {user.permissions.educationExpense && (
          <Button
            color="primary"
            component={Link}
            to="/education-expenses"
          >
            Luentopalkkio
          </Button>
        )}
      </React.Fragment>
    );
  }

  return (
    <Paper elevation={4} className={classes.Paper}>
      <div>
        <img className={classes.Logo} src={SuekLogo} alt="SUEK" />
      </div>
      {content}
    </Paper>
  );
};

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    isAuthenticated: state.auth.user !== null,
    user: state.auth.user
  };
};

export default connect(mapStateToProps)(Home);
